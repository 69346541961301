import { client } from 'Gql';
import {
  CREATE_CUSTOMER_ADDRESS,
  CUSTOMER_ADDRESSES_QUERY,
  DELETE_CUSTOMER_ADDRESS,
  UPDATE_CUSTOMER_ADDRESS,
} from 'Gql/Profile/Address';
import { setAuth } from 'Utils/setAuth';

// Fetch the all address
const AllAddress = async () => {
  const response = await client.query({
    query: CUSTOMER_ADDRESSES_QUERY,
    context: setAuth(),
    fetchPolicy: 'no-cache',
  });

  const {
    data: { customer },
  } = response;

  const defaultAddress = customer?.addresses.filter((address) => address.default_shipping);
  const otherAddresses = customer?.addresses.filter((address) => !address.default_shipping);
  return { defaultAddress, otherAddresses };
};

// Add Address into the server
const AddAddress = async ({
  address,
  city,
  firstname,
  isChecked,
  lastname,
  phone,
  pincode,
  state,
}) => {
  const response = await client.mutate({
    mutation: CREATE_CUSTOMER_ADDRESS,
    context: setAuth(),
    variables: {
      input: {
        region: {
          region: state.label,
          region_code: state.value,
          region_id: state.id,
        },
        country_code: 'IN',
        street: [address],
        telephone: phone,
        postcode: pincode,
        city,
        firstname,
        lastname,
        default_shipping: isChecked,
        default_billing: isChecked,
      },
    },
  });
  return response.data;
};

// Delete address
const DeleteAddress = async (id) => {
  const response = await client.mutate({
    mutation: DELETE_CUSTOMER_ADDRESS,
    context: setAuth(),
    variables: {
      id,
    },
  });
  return { status: response.data.deleteCustomerAddress, id };
};

// Update user address
const UpdateAddress = async (data) => {
  const { addressId, defaultAddress, address, city, firstname, lastname, phone, pincode, state } =
    data;

  const response = await client
    .mutate({
      mutation: UPDATE_CUSTOMER_ADDRESS,
      context: setAuth(),
      variables: {
        id: addressId,
        input: {
          region: {
            region: state.label,
            region_code: state.value,
            region_id: state.id,
          },
          street: address,
          country_code: 'IN',
          telephone: phone,
          postcode: pincode,
          city,
          firstname,
          lastname,
          default_shipping: defaultAddress,
          default_billing: defaultAddress,
        },
      },
    })
    .catch((err) => {
      throw new Error(err);
    });

  return response.data;
};

const addressService = {
  AllAddress,
  AddAddress,
  DeleteAddress,
  UpdateAddress,
};

export default addressService;
