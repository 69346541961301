import { gql } from '@apollo/client';

export const WISHLIST_ID = gql`
  query getWishlistID {
    customer {
      wishlists {
        id
        items_count
        items_v2(currentPage: 0, pageSize: 0) {
          items {
            ... on ConfigurableWishlistItem {
              configurable_options {
                configurable_product_option_uid
                configurable_product_option_value_uid
                value_label
                option_label
              }
            }
            id
            product {
              uid
              sku
            }
          }
        }
      }
    }
  }
`;

export const WISHLIST_COUNT = gql`
  query ($id: ID!) {
    customer {
      wishlist_v2(id: $id) {
        id
        items_count
        items_v2 {
          items {
            id
            product {
              uid
              sku
            }
          }
        }
      }
    }
  }
`;
export const WISHLIST = gql`
  query getWishlist($currentPage: Int!, $pageSize: Int!) {
    customer {
      wishlists {
        id
        __typename
        items_count
        items_v2(currentPage: $currentPage, pageSize: $pageSize) {
          page_info {
            total_pages
          }
          items {
            ... on ConfigurableWishlistItem {
              configurable_options {
                configurable_product_option_uid
                configurable_product_option_value_uid
                value_label
                option_label
              }
              configured_variant {
                sku
                stock_status
                only_x_left_in_stock
                rating_summary
                image {
                  label
                  sizes {
                    image_id
                    url
                  }
                }
                thumbnail {
                  sizes {
                    image_id
                    url
                  }
                  label
                  url
                }
                price_range {
                  minimum_price {
                    discount {
                      amount_off
                    }
                    regular_price {
                      value
                      currency
                    }
                    final_price {
                      value
                      currency
                    }
                  }
                }
              }
            }
            id
            quantity
            product {
              child_details {
                attribute_uid
                parent_sku
              }
              uid
              name
              sku
              stock_status
              only_x_left_in_stock
              brand_name
              brand_label
              labels
              free_product_available
              rating_summary
              offer_rules {
                discount
                type
                name
                show_on_product
              }
              image {
                label
                sizes {
                  image_id
                  url
                }
              }
              thumbnail {
                sizes {
                  image_id
                  url
                }
                url
                label
              }
              media_gallery {
                url
                label
                ... on ProductVideo {
                  video_content {
                    media_type
                    video_provider
                    video_url
                    video_title
                    video_description
                    video_metadata
                  }
                }
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
              }
              ... on ConfigurableProduct {
                configurable_options {
                  id
                  attribute_id
                  label
                  attribute_code
                  values {
                    uid
                    value_index
                    label
                    swatch_data {
                      value
                    }
                  }
                }
                variants {
                  attributes {
                    uid
                    label
                    code
                    value_index
                  }
                  product {
                    uid
                    name
                    sku
                    stock_status
                    only_x_left_in_stock
                    brand_name
                    labels
                    rating_summary
                    thumbnail {
                      sizes {
                        image_id
                        url
                      }
                      url
                      label
                    }
                    media_gallery {
                      url
                      label
                      ... on ProductVideo {
                        video_content {
                          media_type
                          video_provider
                          video_url
                          video_title
                          video_description
                          video_metadata
                        }
                      }
                    }
                    description {
                      html
                    }
                    key_ingredients
                    direction_for_use
                    price_range {
                      minimum_price {
                        regular_price {
                          value
                          currency
                        }
                        final_price {
                          value
                          currency
                        }
                        discount {
                          amount_off
                          percent_off
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_WISHLIST_VARIANTS = gql`
  mutation ($wishlistID: ID!, $itemid: ID!, $selectedOptions: [ID!]) {
    updateProductsInWishlist(
      wishlistId: $wishlistID
      wishlistItems: [
        { wishlist_item_id: $itemid, selected_options: $selectedOptions, quantity: 1 }
      ]
    ) {
      wishlist {
        id
        items_count
        items_v2 {
          items {
            ... on ConfigurableWishlistItem {
              configurable_options {
                configurable_product_option_uid
                configurable_product_option_value_uid
                value_label
                option_label
              }
              configured_variant {
                sku
                stock_status
                only_x_left_in_stock
                rating_summary
                thumbnail {
                  sizes {
                    image_id
                    url
                  }
                  label
                  url
                }
                price_range {
                  minimum_price {
                    discount {
                      amount_off
                    }
                    regular_price {
                      value
                      currency
                    }
                    final_price {
                      value
                      currency
                    }
                  }
                }
              }
            }
            id
            quantity
            product {
              uid
              name
              sku
              stock_status
              only_x_left_in_stock
              brand_name
              brand_label
              labels
              free_product_available
              rating_summary
              offer_rules {
                discount
                type
                name
                show_on_product
              }
              thumbnail {
                sizes {
                  image_id
                  url
                }
                url
                label
              }
              media_gallery {
                url
                label
                ... on ProductVideo {
                  video_content {
                    media_type
                    video_provider
                    video_url
                    video_title
                    video_description
                    video_metadata
                  }
                }
              }
              description {
                html
              }
              key_ingredients
              direction_for_use
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
              }
              ... on ConfigurableProduct {
                configurable_options {
                  id
                  attribute_id
                  label
                  attribute_code
                  values {
                    uid
                    value_index
                    label
                    swatch_data {
                      value
                    }
                  }
                }
                variants {
                  attributes {
                    uid
                    label
                    code
                    value_index
                  }
                  product {
                    uid
                    name
                    sku
                    stock_status
                    only_x_left_in_stock
                    brand_name
                    labels
                    rating_summary
                    thumbnail {
                      sizes {
                        image_id
                        url
                      }
                      url
                      label
                    }
                    media_gallery {
                      url
                      label
                      ... on ProductVideo {
                        video_content {
                          media_type
                          video_provider
                          video_url
                          video_title
                          video_description
                          video_metadata
                        }
                      }
                    }
                    description {
                      html
                    }
                    key_ingredients
                    direction_for_use
                    price_range {
                      minimum_price {
                        regular_price {
                          value
                          currency
                        }
                        final_price {
                          value
                          currency
                        }
                        discount {
                          amount_off
                          percent_off
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ADD_TO_WISHLIST = gql`
  mutation ($wishlistID: ID!, $sku: String!, $selectedOptions: [ID]!) {
    addProductsToWishlist(
      wishlistId: $wishlistID
      wishlistItems: [{ sku: $sku, quantity: 1, selected_options: $selectedOptions }]
    ) {
      wishlist {
        id
        items_count
        items_v2(currentPage: 0, pageSize: 0) {
          page_info {
            total_pages
          }
          items {
            ... on ConfigurableWishlistItem {
              configurable_options {
                configurable_product_option_uid
                configurable_product_option_value_uid
                value_label
                option_label
              }
              configured_variant {
                sku
                stock_status
                only_x_left_in_stock
                rating_summary
                image {
                  label
                  sizes {
                    image_id
                    url
                  }
                }
                thumbnail {
                  sizes {
                    image_id
                    url
                  }
                  label
                  url
                }
                price_range {
                  minimum_price {
                    discount {
                      amount_off
                    }
                    regular_price {
                      value
                      currency
                    }
                    final_price {
                      value
                      currency
                    }
                  }
                }
              }
            }
            id
            quantity
            product {
              uid
              name
              sku
              stock_status
              only_x_left_in_stock
              brand_name
              brand_label
              labels
              free_product_available
              rating_summary
              offer_rules {
                discount
                type
                name
                show_on_product
              }
              image {
                label
                sizes {
                  image_id
                  url
                }
              }
              thumbnail {
                sizes {
                  image_id
                  url
                }
                url
                label
              }
              media_gallery {
                url
                label
                ... on ProductVideo {
                  video_content {
                    media_type
                    video_provider
                    video_url
                    video_title
                    video_description
                    video_metadata
                  }
                }
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
              }
              ... on ConfigurableProduct {
                configurable_options {
                  id
                  attribute_id
                  label
                  attribute_code
                  values {
                    uid
                    value_index
                    label
                    swatch_data {
                      value
                    }
                  }
                }
                variants {
                  attributes {
                    uid
                    label
                    code
                    value_index
                  }
                  product {
                    uid
                    name
                    sku
                    stock_status
                    only_x_left_in_stock
                    brand_name
                    labels
                    rating_summary
                    thumbnail {
                      sizes {
                        image_id
                        url
                      }
                      url
                      label
                    }
                    media_gallery {
                      url
                      label
                      ... on ProductVideo {
                        video_content {
                          media_type
                          video_provider
                          video_url
                          video_title
                          video_description
                          video_metadata
                        }
                      }
                    }
                    description {
                      html
                    }
                    key_ingredients
                    direction_for_use
                    price_range {
                      minimum_price {
                        regular_price {
                          value
                          currency
                        }
                        final_price {
                          value
                          currency
                        }
                        discount {
                          amount_off
                          percent_off
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CUSTOM_ATTRIBUTE_METADATA_QUERY = gql`
  query ($attributeCode: String!) {
    customAttributeMetadata(
      attributes: [{ attribute_code: $attributeCode, entity_type: "catalog_product" }]
    ) {
      items {
        attribute_code
        attribute_type
        entity_type
        input_type
        attribute_options {
          value
          label
        }
        storefront_properties {
          use_in_product_listing
          use_in_layered_navigation
          use_in_search_results_layered_navigation
          visible_on_catalog_pages
          position
        }
      }
    }
  }
`;

export const GET_ALL_WISHLIST = gql`
  query GetAllWishlist($currentPage: Int!, $pageSize: Int!) {
    customer {
      wishlists {
        id
        __typename
        items_count
        items_v2(currentPage: $currentPage, pageSize: $pageSize) {
          items {
            ... on ConfigurableWishlistItem {
              configurable_options {
                configurable_product_option_uid
                configurable_product_option_value_uid
                value_label
                option_label
              }
            }
            id
            product {
              uid
              sku
            }
          }
        }
      }
    }
  }
`;
