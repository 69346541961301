export default (product, image_id = 'product_custom_web_thumbnail', image_type = 'image') => {
  const url = product[image_type]?.sizes?.filter((ele) => ele.image_id === image_id);
  // if (url) return url[0].url;
  // return product?.thumbnail?.url;
  let media_gallery_images = [];
  if (url) media_gallery_images = product?.media_gallery;
  if (!media_gallery_images || media_gallery_images.length === 0) {
    return [product?.thumbnail];
  }
  return media_gallery_images;
};

export const wishlistImgUrl = (
  product,
  variant,
  image_id = 'plp_web_product_image',
  image_type = 'image',
) => {
  const url = product[variant][image_type]?.sizes?.filter((ele) => ele?.image_id === image_id);
  if (url) return { ...url[0], label: product[variant][image_type]?.label };
  return product[variant]?.thumbnail;
  // if (url)
  //   return product?.media_gallery?.map((item) => item.url);
  // return product?.thumbnail?.url;
};

export const PRODUCT_CUSTOM_WEB_THUMBNAIL = 'product_custom_web_thumbnail';
export const PLP_WEB_PRODUCT_IMAGE = 'plp_web_product_image';
