import './GSTVerification.css';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';

function GSTVerification({ nextForm }) {
  const gstinState = useSelector((state) => state.b2bSignIn.gstin);
  const entityNameState = useSelector((state) => state.b2bSignIn.entityName);
  const principalAddressState = useSelector((state) => state.b2bSignIn.principalAddress);

  const formikProps = {
    initialValues: {
      gstin: gstinState,
      entityName: entityNameState,
      bussinessAddress: principalAddressState,
    },
  };

  const onConfirmHandler = () => {
    nextForm();
  };

  const { values } = useFormik(formikProps);

  return (
    <div className='gstVerification'>
      <div className='gstVerification-inputHeader'>
        <div className='gstVerifcation-inputText'>GSTIN</div>
        <input placeholder='Enter Email ID' disabled name='gstin' value={values.gstin} />
      </div>
      <div className='gstVerification-inputHeader'>
        <div className='gstVerifcation-inputText'>Entity Name</div>
        <input
          placeholder='Enter Entity Name'
          disabled
          name='entityName'
          value={values.entityName}
        />
      </div>
      <div className='gstVerification-inputHeader'>
        <div className='gstVerifcation-inputText'>Business Address</div>
        <div placeholder='Enter Business Address' disabled name='bussinessAddress'>
          {values.bussinessAddress}
        </div>
      </div>
      <div className='gstVerification-btn'>
        <button
          className='gstVerification-submit whiteCta'
          onClick={() => onConfirmHandler()}
          tabIndex={0}
          type='button'
        >
          CONFIRM
        </button>
      </div>
    </div>
  );
}

export default GSTVerification;
