import React from 'react';
import './loyaltyTnc.css';
import { Link } from 'react-router-dom';

const LoyaltyTnc = () => (
  <>
    <span className='side-back' style={{ maxHeight: 'unset' }}>
      <h3 className='cancellation-return'>Maison D' Glam Loyalty Program Terms And Conditions</h3>
    </span>
    <div className='show-underline' />
    <div className='acccor loyaltyTnc pb-0'>
      <ol className='custom-number-ol'>
        <li className=' parent-list'>
          <div>
            <h4>&nbsp;DEFINITIONS</h4>
            <ol className='custom-number-ol'>
              <li className='ol-child-li'>
                "Maison D' Glam" refers to the loyalty program of Alternative Tech Lab under this
                brand name. Alternativ Tech Lab Private Limited, a Company registered under the
                Companies Act 2013, having its Registered Office at Plot No. 22, Electronic City,
                Sector 18, Gurgaon, Haryana- 122015, India and hereinafter referred to as “Company”
                which expression shall, unless repugnant to the context, mean and include its
                transferees, successors and assigns.
              </li>
              <li className='ol-child-li'>
                “Maison D' Glam Rewards Program’ shall hereafter be referred to as ‘Rewards
                Program’.
              </li>
              <li className='ol-child-li'>
                "Reward Points" shall be the reward points awarded under the rewards program.
                Members can earn Rewards Points on the purchase of eligible products only.
              </li>
              <li className='ol-child-li'>
                Rewards tiers, in the context of a loyalty program, are different levels or stages
                that customers can achieve based on their engagement, spending, or loyalty. Each
                tier typically offers various benefits, incentives, or rewards that become more
                attractive as customers progress to higher tiers
              </li>
              <li className='ol-child-li'>
                "Member" means a person holding a membership and registered with Rewards Program in
                the manner prescribed on the Maison D' Glam Website.
              </li>
              <li className='ol-child-li'>
                "Website" shall mean the website at https://maisondglam.com
              </li>
              <li className='ol-child-li'>
                "Effective Date” shall mean the date from which the Rewards Program commence(s),
                which shall be communicated by the company to the Member/s from time to time.
                However, the Member can avail the benefits under rewards program only from the date
                of registration with the rewards program.
              </li>
              <li className='ol-child-li'>
                "Termination Date" shall mean the date on which the Rewards Program is / are
                terminated by the company at its sole discretion.
              </li>
              <li className='ol-child-li'>
                If during the period of any promotional scheme offered by Rewards Program or
                thereafter, the use of the account is withdrawn, or cancelled, or is liable to be
                cancelled, or the Account is termed as a delinquent account, then any offer/benefit
                including but not limited to gift/discounts/cash-back offered during the promotion
                period shall ipso facto stand immediately and automatically cancelled for the
                Member.
              </li>
            </ol>
          </div>
        </li>

        <li className='parent-list custom-padding-right'>
          <div>
            <h4>&nbsp;"Terms and Conditions applicable to rewards program</h4>
            <ol className='custom-number-ol'>
              <li className='ol-child-li'>
                The Rewards Program shall come in force from the Effective Date and shall be
                available to Member/s holding the Account on and after the Effective Date.
              </li>
              <li className='ol-child-li'>
                BY APPLYING FOR MEMBERSHIP IN THE PROGRAM, YOU AGREE (OR, IF YOU ARE A MINOR, YOUR
                PARENT OR LEGAL GUARDIAN HAS AGREED) TO BE BOUND BY THESE TERMS AND CONDITIONS
                ("TERMS")
              </li>
              <li className='ol-child-li prevnt-flex'>
                Within the Rewards Program, the company will allocate Reward Points for purchases
                made through various channels including:
                <ol className='channels-ul'>
                  <li>Physical Retail Stores </li>
                  <li>GK Hair website </li>
                  <li>Glamourbook </li>
                  <li>Le Bloom </li>
                  <li>Keune Website </li>
                  <li>pH Website </li>
                  <li>Other introduced websites</li>
                </ol>
              </li>
              <li className='ol-child-li prevnt-flex'>
                Below are the reward tiers and their corresponding reward points ranges.
                <div className='scrollWrapper'>
                  <table>
                    <tr>
                      <th>Rewards Tiers</th>
                      <th>Reward Points Range</th>
                    </tr>
                    <tr>
                      <td>Platinum</td>
                      <td>100-2000</td>
                    </tr>
                    <tr>
                      <td>Prestige</td>
                      <td>2001-10,000</td>
                    </tr>
                    <tr>
                      <td>Privé</td>
                      <td>10,000 onwards</td>
                    </tr>
                  </table>
                </div>
              </li>
              <li className='ol-child-li prevnt-flex'>
                The earning system for Rewards Program is outlined as follows:
                <div className='scrollWrapper'>
                  <table>
                    <tr>
                      <th>Avenues to earn points</th>
                      <th>D2C Ecommerce Website</th>
                      <th>Own Marketplace / Applications</th>
                      <th>Purchase from owned / partner Offline Stores</th>
                    </tr>
                    <tr>
                      <td>Registration</td>
                      <td>100</td>
                      <td>100</td>
                      <td>100</td>
                    </tr>
                    <tr>
                      <td>Purchase/Booking</td>
                      <td>15% of cart value</td>
                      <td>
                        10%
                        <br />
                        15% for associated brands
                      </td>
                      <td>15%</td>
                    </tr>
                    <tr>
                      <td>Referral</td>
                      <td>100</td>
                      <td>100</td>
                      <td>NA</td>
                    </tr>
                    <tr>
                      <td>On Refree signing up</td>
                      <td>100</td>
                      <td>100</td>
                      <td>NA</td>
                    </tr>
                    <tr>
                      <td>On Submitting Reviews on App Store*</td>
                      <td>100</td>
                      <td>100</td>
                      <td>NA</td>
                    </tr>
                    <tr>
                      <td>On Subscribing to newsletter*</td>
                      <td>100</td>
                      <td>100</td>
                      <td>NA</td>
                    </tr>
                    <tr>
                      <td>Birthday (Valid on 1st purchase during birthday month)</td>
                      <td>10% off</td>
                      <td>10% off + Surprise Gift</td>
                      <td>10% off + Luxe Birthday Gift</td>
                    </tr>
                  </table>
                </div>
                *As and when Company introduces
              </li>
              <li className='ol-child-li prevnt-flex'>
                Additional Benefits are listed as per the below table:
                <div className='scrollWrapper'>
                  <table>
                    <tr>
                      <th />
                      <th>Platinum</th>
                      <th>Prestige</th>
                      <th>Privé</th>
                    </tr>
                    <tr>
                      <td>Early Access to Sale</td>
                      <td>NA</td>
                      <td>Applicable</td>
                      <td>Applicable</td>
                    </tr>
                    <tr>
                      <td>Priority Care Connect</td>
                      <td>NA</td>
                      <td>Applicable</td>
                      <td>Applicable</td>
                    </tr>
                    <tr>
                      <td>Birthday*</td>
                      <td>10% off</td>
                      <td>10% off + Surprise Gift</td>
                      <td>10% off + Luxe Birthday Gift</td>
                    </tr>
                  </table>
                </div>
                *Valid on 1st purchase during birthday month
              </li>
              <li className='ol-child-li prevnt-flex'>
                The Member can redeem his/ her accumulated Reward Points against the purchase of
                products and services provided by Rewards Program as listed below:
                <div className='scrollWrapper'>
                  <table>
                    <tr>
                      <th />
                      <th>Platinum</th>
                      <th>Prestige</th>
                      <th>Privé</th>
                    </tr>
                    <tr>
                      <td>Redemption</td>
                      <td colSpan={3}>
                        *10% of cart value can be redeemed against loyalty points not exceeding
                        Rs.1000
                      </td>
                    </tr>
                    <tr>
                      <td>Conversion Ratio</td>
                      <td>Rs. 0.2 per 1 loyalty point</td>
                      <td>Rs. 0.2 per 1 loyalty point</td>
                      <td>Rs. 0.2 per 1 loyalty point</td>
                    </tr>
                  </table>
                </div>
                Note: The redemption would be on total cart value which may or may not include
                taxes, shipping charges (if applicable), gift charges or any other charges (as
                applicable on cart) etc. depending upon our individual brand policies.
              </li>
              <li className='ol-child-li'>
                Upon redemption of the Reward Points, the Reward Points so redeemed shall
                automatically stand reduced or debited from the accumulated Reward Points in the
                Account.
              </li>
              <li className='ol-child-li'>
                Reward Points cannot be encashed and do not carry any real money value. Reward
                Points are only redeemable as a discount against the purchase price of the products
                on various channels of the company
              </li>
              <li className='ol-child-li'>
                If any time before the Scheme Termination Date the use of the select Member account
                is withdrawn, or cancelled, or is liable to be cancelled, or the Member Account is
                termed as a Delinquent Account, all the Reward Points then standing to the credit of
                the Member shall ipso facto stand immediately and automatically cancelled.
              </li>
              <li className='ol-child-li'>
                Such cancelled Reward Points shall not be credited to the Member Account even if the
                Member reinstates his/ her membership with Rewards Program
              </li>
              <li className='ol-child-li'>
                At the end of the Scheme Termination Date, the Reward Points standing to the credit
                of the Member which have not been redeemed within the stipulated time shall lapse
                automatically.
              </li>
            </ol>
          </div>
        </li>

        <li className='  parent-list'>
          <div>
            <h4>&nbsp; TAX</h4>
            <p>
              Any tax or other liabilities or charges payable to the government or any other
              authority or body established by law (central, state or local), which may arise or
              accrue to the Member by redemption of the reward points under the respective Scheme,
              as aforesaid, shall be to the sole account of the Member. Tax deducted at source, if
              any, on the monetary value of the goods/services shall be payable by the Member.
            </p>
          </div>
        </li>

        <li className='  parent-list'>
          <div>
            <h4>&nbsp; LIMITATION OF LIABILITY</h4>
            <p>
              In no event will company., its subsidiaries and affiliates, its franchisees or
              licensees, any participating brand, and each of their respective directors, officers,
              employees, and agents be liable for any direct, indirect, special, exemplary,
              punitive, incidental or consequential damages of any kind, whether based in contract,
              tort or otherwise, which arise out of or are in any way connected with the loyalty
              program, these program rules, or the company’s operation of the loyalty program.
            </p>
          </div>
        </li>

        <li className='  parent-list'>
          <div>
            <h4>&nbsp; FORCE MAJEURE</h4>
            <ol className='custom-number-ol'>
              <li className='ol-child-li'>
                In case of failure to perform, or the delay in performance of, any of its
                obligations if, and to the extent that, such failure or delay is caused by events
                substantially beyond the its control, including but not limited to acts of God, acts
                of the public enemy or governmental body in its sovereign or contractual capacity,
                epidemics, pandemic, war, terrorism, floods, strikes, civil unrest, fire, power
                outage, and/or unusually severe weather, the Company shall not be liable for such
                failure or delay in performance or any of its obligation.
              </li>
            </ol>
          </div>
        </li>

        <li className='  parent-list'>
          <div>
            <h4>&nbsp; DISPUTE RESOLUTION AND GOVERNING LAW</h4>
            <ol className='custom-number-ol'>
              <li className='ol-child-li'>
                Any disputes arising out of or related to rewards program or these Program Rules
                will be handled individually without any class action, and will be governed by,
                construed and enforced in accordance with the laws of the State of Delhi, India,
                without regard to its conflicts of law rules. The exclusive jurisdiction for any
                claim or action arising out of or relating to rewards program or the Program Rules
                may be filed only in the state or federal courts located in the State of Delhi,
                India.{' '}
              </li>
            </ol>
          </div>
        </li>

        <li className='  parent-list'>
          <div>
            <h4>&nbsp; UNLAWFUL OR PROHIBITED USE</h4>
            <ol className='custom-number-ol'>
              <li className='ol-child-li'>
                You warrant to the company that you will comply with all applicable laws, statutes,
                ordinances and regulations regarding the use of the Services and any other related
                activities. You further warrant that you will not use the Platforms in any way
                prohibited by terms contained in the Terms of Use or under applicable law.{' '}
              </li>
            </ol>
          </div>
        </li>

        <li className='  parent-list'>
          <div>
            <h4>&nbsp; SUBJECT TO LAW</h4>
            <ol className='custom-number-ol'>
              <li className='ol-child-li'>
                Membership in the rewards program and the earning and redeeming of Points are
                subject to all applicable local laws and regulations. Membership in the Rewards
                Program, Member benefits, and Awards are offered in good faith; however, they may
                not be available if prohibited or restricted by applicable law or regulation in
                India or Member’s jurisdiction of residence. If any part of these Loyalty Program
                Rules is held to be unlawful or unenforceable, that part will be deemed deleted from
                these Program Rules in such jurisdiction and the remaining provisions will remain in
                force, subject to Sections 2.7, 2.8 and 2.9.
              </li>
            </ol>
          </div>
        </li>

        <li className='  parent-list'>
          <div>
            <h4>&nbsp; INDEMNIFICATION</h4>
            <ol className='custom-number-ol'>
              <li className='ol-child-li'>
                You agree to defend, indemnify and hold us, our parents, subsidiaries, affiliates,
                partners, licensors, officers, directors, employees, and agents harmless for any
                loss, damages or costs, including reasonable attorneys' fees, resulting from any
                third-party claim, action, or demand resulting from your participation in the
                Program in violation of any law, rule, regulation or these Program Terms.
              </li>
            </ol>
          </div>
        </li>

        <li className='  parent-list'>
          <div>
            <h4>&nbsp; SEVERABILITY</h4>
            <ol className='custom-number-ol'>
              <li className='ol-child-li'>
                If any provision of the Terms of Use is determined to be invalid or unenforceable in
                whole or in part, such invalidity or unenforceability shall attach only to such
                provision and the remaining part of such provision and all other provisions of the
                Terms of Use shall continue to be in full force and effect.
              </li>
            </ol>
          </div>
        </li>

        <li className='  parent-list'>
          <div>
            <h4>&nbsp; PRIVACY</h4>
            <p>
              The personal information collected from you in connection with the Program, including
              but not limited to purchases made in connection with your Program membership, will be
              used and disclosed by us in accordance with our{' '}
              <Link to='/privacypolicies'>privacy policy</Link>.
            </p>
          </div>
        </li>

        <li className='  parent-list'>
          <div>
            <h4>&nbsp;ENTIRE AGREEMENT</h4>
            <p>
              The Loyalty Program Rules, together with any other terms and conditions, rules, or
              regulations incorporated herein or referred to herein constitute the entire agreement
              between the Company and Members relating to the subject matter hereof, and supersede
              any prior understandings or agreements (whether oral or written) regarding the subject
              matter, and may not be amended or modified except in writing or by making such
              amendments or modifications available on the Loyalty Program website.
            </p>
          </div>
        </li>

        <li className='  parent-list'>
          <div>
            <h4>&nbsp; MISCELLANEOUS</h4>
            <ol className='custom-number-ol'>
              <li className='ol-child-li'>
                Company reserves the right to nullify the reward points earned by the Member under
                the respective Schemes in case of induced closure of the Member Account or the
                termination/cancellation of the respective Scheme.
              </li>
              <li className='ol-child-li'>
                Company expressly reserves the right, at any time and without prior notice to the
                Member, to add, alter, modify, change or vary all or in part, or withdraw altogether
                the Schemes or any other reward point scheme that the company may introduce from
                time to time.
              </li>
              <li className='ol-child-li'>
                Member/s may note that accrual of reward points shall vary across the different
                Reward Tiers as mentioned under clause 2.3.
              </li>
              <li className='ol-child-li'>
                Option of redemption once invoked by the Member cannot be subsequently
                canceled/withdrawn/changed at the instance of any Member.
              </li>
              <li className='ol-child-li'>
                Reward points once exchanged under any specific scheme/promotional offer that may be
                either offered by Company or by Company’s tie up with a partner brand or for any
                other partner's loyalty programmed cannot be subsequently transferred back to the
                Member Account or canceled at the instance of the Member.
              </li>
              <li className='ol-child-li'>
                All visuals of the goods/services in the redemption catalogue or related web pages
                are indicative only. Company shall not be held responsible where any manufacturer's
                supplier of products offered to Members withdraws, cancels, alters or amends the
                products.
              </li>
              <li className='ol-child-li'>
                All products/vouchers/services available for redemption are subject to availability
                of the same and manufacturer's warranties/restrictions at the time of redemption.
              </li>
              <li className='ol-child-li'>
                Company does not guarantee and make any representation about the quality, usefulness
                and worthiness of the products/vouchers/services and shall not be responsible if the
                same are in any way found to be defective.
              </li>
              <li className='ol-child-li'>
                The Schemes are not available wherever prohibited and/or on such
                merchandise/products/ services for which such Schemes cannot be offered, for any
                reason whatsoever.
              </li>
              <li className='ol-child-li'>
                Company reserves the right to modify/change all or any of the Terms and Conditions
                applicable to the Rewards Program without assigning any reasons or without any prior
                intimation whatsoever to the Member. Company also reserves the right to discontinue
                the Rewards Program without assigning any reasons or without any prior intimation
                whatsoever. Nothing contained herein shall amount to a commitment or representation
                by the Company to continue the Rewards Program.
              </li>
              <li className='ol-child-li'>Reward points are not transferable.</li>
            </ol>
          </div>
        </li>

        <li className='  parent-list'>
          <div>
            <h4>&nbsp; NOTICES</h4>
            <p>
              All notices and communications shall be in writing, in English and shall be deemed
              given if delivered personally or by commercial messenger or courier service, or mailed
              by registered or certified mail (return receipt requested) or sent by email, with due
              acknowledgment or complete transmission to the following address: Plot No. 22,
              Electronic City, Sector 18, Gurgaon, Haryana- 122015, India.
            </p>
          </div>
        </li>

        <li className='  parent-list'>
          <div>
            <h4>&nbsp; CONTACT US</h4>
            <p>
              For information about the Program and your membership, contact Customer Service at
              support@maisondglam.com. You will be required to confirm your full name and phone
              numbers in order to validate your account. We are not responsible for requests or
              correspondence lost or delayed in the mail or over the Internet.
            </p>
          </div>
        </li>

        <li className='  parent-list'>
          <div>
            <h4>&nbsp; INTERPRETATION</h4>
            <ol className='custom-number-ol'>
              <li className='ol-child-li'>
                Headings, subheadings, titles, subtitles to clauses, sub-clauses and paragraphs are
                for information only and shall not form part of the operative provisions of the
                Terms of Use and shall be ignored in construing the same.
              </li>
              <li className='ol-child-li'>
                Words denoting the singular shall include the plural and words denoting any gender
                shall include all genders.
              </li>
              <li className='ol-child-li'>
                The words “include” and “including” are to be construed without limitation.
              </li>
            </ol>
          </div>
        </li>
      </ol>
    </div>
  </>
);

export default LoyaltyTnc;
