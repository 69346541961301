import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import OTPInput from 'Hooks/Otp';
import {
  CREATE_ACCOUNT_OTP,
  CREATE_ACCOUNT_OTP_VERIFY,
  LOGIN_OTP,
  LOGIN_OTP_VERIFY,
} from 'B2B/Gql';
import editIcon from 'B2B/Assets/Icons/editIcon.svg';
import './SignInOTPVerification.css';
import { useSelector } from 'react-redux';
import { HideContact } from 'Utils/hideContact';
import { notification } from 'Utils/Toast';

function SignInOTPVerification({ nextForm, prevForm }) {
  const mobileNumberState = useSelector((state) => state?.b2bSignIn?.mobileNumber);
  const isNewCompanyState = useSelector((state) => state?.b2bSignIn?.isNewCompany);

  const [err, setErr] = useState('');
  const [otp, setOtp] = useState('');
  const [resendTime, setResendTime] = useState(30);

  const [loginOTPVerify] = useLazyQuery(LOGIN_OTP_VERIFY);
  const [createAccountOTPVerify] = useLazyQuery(CREATE_ACCOUNT_OTP_VERIFY);
  const [loginOTPMutation] = useMutation(LOGIN_OTP);
  const [createAccountOTPMutation] = useMutation(CREATE_ACCOUNT_OTP);

  useEffect(() => {
    if (resendTime === 0) {
      return;
    }
    const timeout = setInterval(() => {
      setResendTime((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timeout);
  }, [resendTime]);

  const onEditIconHandler = () => {
    prevForm();
  };

  const onChangeHandler = (otp) => {
    setOtp(() => otp);
  };

  const onResendHandler = async () => {
    if (resendTime !== 0) {
      return;
    }
    setResendTime(30);
    if (isNewCompanyState) {
      await createAccountOTPMutation({
        variables: {
          mobileNumber: `91${mobileNumberState}`,
          websiteId: 1,
        },
      });
    } else {
      await loginOTPMutation({
        variables: {
          mobileNumber: `91${mobileNumberState}`,
          websiteId: 1,
        },
      });
    }
  };

  const onVerifyHandler = async () => {
    try {
      if (isNewCompanyState) {
        await createAccountOTPVerify({
          variables: {
            mobileNumber: `91${mobileNumberState}`,
            otp,
          },
        })
          .then((res) => {
            if (res?.data?.createAccountOTPVerify?.status) {
              nextForm();
            } else {
              setErr('Verification Code Invalid');
            }
          })
          .catch(() => {
            setErr('Verification Code Invalid');
          });
      } else {
        await loginOTPVerify({
          variables: {
            mobileNumber: `91${mobileNumberState}`,
            otp,
          },
        }).then((res) => {
          if (res?.data?.loginOTPVerify?.status) {
            localStorage.setItem('token', res?.data?.loginOTPVerify?.token);
            localStorage.setItem('isB2B', true);
            notification.success('Yay! You logged in successfully');
            window.location.href = '/';
          } else {
            setErr('Verification Code Invalid');
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='signInOTPVerification'>
      <div className='signInOTPVerification-inputHeader'>
        Please enter 6 digit code sent to +91{HideContact(mobileNumberState, 2, '****', 4)}
        <img
          src={editIcon}
          onClick={() => onEditIconHandler()}
          tabIndex={0}
          role='button'
          alt='editIcon'
        />
      </div>
      <div className='signInOTPVerification-input'>
        <OTPInput
          autoFocus
          length={6}
          className='otp-input'
          inputClassName='otpInput'
          hasErrored={err}
          onChangeOTP={onChangeHandler}
        />
        {err && <div className='validationError'>{err}</div>}
      </div>
      <div className='signInOTPVerification-resend'>
        {resendTime !== 0 && (
          <div className='signInOTPVerification-resendTime'>
            00:{resendTime < 10 ? `0${resendTime}` : resendTime}
          </div>
        )}
        <div
          className={`signInOTPVerification-resentBtn ${resendTime !== 0 && 'disabled'}`}
          tabIndex={0}
          role='button'
          onClick={() => onResendHandler()}
        >
          Resend Code
        </div>
      </div>
      <div className='signInOTPVerification-btn'>
        <button
          className='signInOTPVerification-verify whiteCta'
          onClick={() => onVerifyHandler()}
          tabIndex={0}
          type='button'
        >
          VERIFY
        </button>
      </div>
    </div>
  );
}

export default SignInOTPVerification;
