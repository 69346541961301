/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import './loginpopup.css';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { CHECK_CUSTOMER_EXISTS, CREATE_ACCOUNT_OTP_MUTATION, LOGIN_OTP_MUTATION } from 'Gql/query';
import { changeFormType, changeTrigger, updateusername } from 'Features/login';
import { LoginSchema } from 'Utils/ValidationSchema/YupSchema';
import loginLogo from 'assets/images/Login Popup/loginLogo.svg';
import { CaretDown } from 'phosphor-react';
import { notification } from 'Utils/Toast';
import { CHECK_COMPANY_USER_EXISTS } from 'B2B/Gql';
import { IsB2BEnable } from 'B2B/Utils/IsB2B';

function SignIn() {
  const dispatch = useDispatch();
  const [Loading, SetLoading] = useState(false);
  const [CheckCustomerExists] = useLazyQuery(CHECK_CUSTOMER_EXISTS, {
    fetchPolicy: 'no-cache',
  });
  const [checkCustomerUserExistsQuery] = useLazyQuery(CHECK_COMPANY_USER_EXISTS, {
    fetchPolicy: 'no-cache',
  });

  const [CreateAccountOTP] = useMutation(CREATE_ACCOUNT_OTP_MUTATION);
  const [LoginOTP] = useMutation(LOGIN_OTP_MUTATION);

  // Handle the form validation
  const formikProps = {
    initialValues: {
      phone: '',
      tnc: false,
    },
    validationSchema: LoginSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      dispatch(updateusername({ type: 'phone', field: `+91${values.phone}` }));
      HandleUserSubmit(values);
    },
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    setErrors,
  } = useFormik(formikProps);

  const HandleUserSubmit = async (value) => {
    try {
      SetLoading(true);
      if (!IsB2BEnable()) {
        const existsQuery = await CheckCustomerExists({
          variables: {
            fieldValue: `91${value.phone}`,
            type: 'mobile',
          },
        });

        if (existsQuery.data.checkCustomerExists) {
          await HandleOTPRequest(`91${value.phone}`);
        } else {
          await HandleAccountCreationOTP(`91${value.phone}`);
        }
      } else {
        const result = await checkCustomerUserExistsQuery({
          variables: {
            field_value: `91${value.phone}`,
            type: 'mobile',
          },
        });

        if (result?.data?.checkCompanyUserExists?.status) {
          if (result?.data?.checkCompanyUserExists?.type === 'b2b') {
            setErrors({
              phone:
                'This number is already registered on GlamourBook Business, kindly try with a new one.',
            });
          } else {
            await HandleOTPRequest(`91${value.phone}`);
          }
        } else {
          await HandleAccountCreationOTP(`91${value.phone}`);
        }
      }
    } catch (error) {
      console.error('Error occurred:', error);
      notification.error(error.message);
    } finally {
      SetLoading(false);
    }
  };

  const HandleOTPRequest = async (mobileNumber) => {
    try {
      const otpResult = await LoginOTP({
        variables: { mobileNumber, websiteId: 1 },
      });
      if (otpResult.data.loginOTP.status) {
        dispatch(changeFormType('VerifyOtpForgotPassword'));
        notification.success('OTP sent successfully');
      } else {
        throw new Error(otpResult.data.loginOTP.message);
      }
    } catch (error) {
      notification.error(error.message);
    }
  };

  const HandleAccountCreationOTP = async (mobileNumber) => {
    try {
      await CreateAccountOTP({
        variables: { mobileNumber, websiteId: 1 },
      });
      dispatch(changeFormType('verfiy_otp'));
    } catch (error) {
      notification.error('Failed to send OTP. Please try again.');
    }
  };

  useEffect(() => {
    document.body.classList.add('login-modal-open');
    return () => {
      document.body.classList.remove('login-modal-open');
    };
  }, []);

  return (
    <div className='login' data-testid='sigin'>
      <div className='login__form'>
        <img src={loginLogo} alt='Glamourbook' width='274' height='100' />
        <p className='subText'>Your one-stop destination for all things beauty and personal care</p>
        <form className='formInputs' onSubmit={handleSubmit}>
          <div className='w-100'>
            <label htmlFor='username'>Mobile Number*</label>
            <div className={`loginInput ${touched.phone && errors.phone ? 'errorShake' : ''}`}>
              <div className='countryCode'>
                +91
                <CaretDown size={16} weight='bold' color='#d9d9d9' />
              </div>
              <input
                type='tel'
                autoComplete='off'
                aria-autocomplete='none'
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                name='phone'
                id='phone'
                data-testid='email-phone'
                placeholder='Enter Mobile Number'
                maxLength={10}
                pattern='[0-9]*'
                inputMode='numeric'
              />
            </div>
            {errors.phone ? (
              <p className='text-validation login-validation' data-testid='siginerr'>
                {errors.phone}
              </p>
            ) : null}

            <div className='d-flex flex-row mt-3'>
              <label className='login-checkbox-button d-flex justify-content-center align-items-center mb-0'>
                <input
                  type='checkbox'
                  className={`login-checkbox-button__input ${
                    touched.tnc && errors.tnc ? 'errorShake' : ''
                  }`}
                  id='tnc'
                  name='tnc'
                  checked={values.tnc}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className=' signup_fotter_text'>
                  I agree to GlamourBook's{' '}
                  <Link
                    onClick={() => {
                      dispatch(changeTrigger());
                    }}
                    to='/termsandconditions'
                  >
                    {' '}
                    <span className='fw-semibold text-decoration-underline'>
                      Terms & Conditions
                    </span>{' '}
                  </Link>
                  and{' '}
                  <Link
                    onClick={() => {
                      dispatch(changeTrigger());
                    }}
                    to='/privacypolicies'
                  >
                    <span className='fw-semibold text-decoration-underline'>Privacy Policy</span>
                  </Link>
                </p>
              </label>
            </div>
            {touched.tnc && errors.tnc ? (
              <p className='text-validation login-validation' data-testid='siginerr'>
                {errors.tnc}
              </p>
            ) : null}
          </div>
          <button
            type='submit'
            disabled={isSubmitting}
            className='loginCta whiteCta'
            id='submit'
            onClick={handleSubmit}
            data-testid='submit-button'
          >
            {Loading ? 'Loading...' : 'Continue'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default SignIn;
