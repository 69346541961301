/* eslint-disable no-param-reassign */

export const reducerAddFilterJson = (state, { payload }) => {
  const { filter, type, isFirstFilter } = payload;
  const firstFilter = { ...state.firstFilters };
  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }
  let newFilter = state.filterJson;
  switch (type) {
    case 'url_filters':
      newFilter = filter;
      break;
    case 'category':
      if (isFirstFilter) {
        if (newFilter.hasOwnProperty('brand_name')) {
          delete newFilter.brand_name;
        }
        if (newFilter.hasOwnProperty('category_uid')) {
          newFilter.category_uid.in = [];
          newFilter.category_uid.in = filter;
        } else {
          newFilter.category_uid = {};
          newFilter.category_uid.in = filter;
        }
      } else if (firstFilter.hasOwnProperty('category_uid')) {
        newFilter.category_uid.in = removeDuplicates([...firstFilter.category_uid.in, ...filter]);
      } else {
        newFilter.category_uid = {};
        newFilter.category_uid.in = filter;
      }

      break;
    case 'brands':
      if (isFirstFilter) {
        if (newFilter.hasOwnProperty('category_uid')) {
          delete newFilter.category_uid;
        }
        if (newFilter.hasOwnProperty('brand_name')) {
          newFilter.brand_name.in = [];
          newFilter.brand_name.in = filter;
        } else {
          newFilter.brand_name = {};
          newFilter.brand_name.in = filter;
        }
      } else if (firstFilter.hasOwnProperty('brand_name')) {
        newFilter.brand_name.in = removeDuplicates([...firstFilter.brand_name.in, ...filter]);
      } else {
        newFilter.brand_name = {};
        newFilter.brand_name.in = filter;
      }

      break;
    case 'size':
      if (!filter.length) delete newFilter.volume_range;
      else {
        newFilter.volume_range = {};
        newFilter.volume_range.in = filter;
      }
      break;
    case 'weight_range':
      if (!filter.length) delete newFilter.weight_range;
      else {
        newFilter.weight_range = {};
        newFilter.weight_range.in = filter;
      }
      break;
    case 'color':
      if (!filter.length) delete newFilter.parent_color;
      else {
        newFilter.parent_color = {};
        newFilter.parent_color.in = filter;
      }
      break;
    case 'discount':
      if (!filter.length) delete newFilter.discount;
      else {
        newFilter.discount = {};
        newFilter.discount.in = filter;
      }

      break;
    case 'price':
      if (Object.keys(filter).length === 0) delete newFilter.price;
      if (filter.to === 0) {
        newFilter.price = {};
        newFilter.price = { from: 0, to: 0 };
      } else {
        newFilter.price = {};
        newFilter.price = filter;
      }

      break;
    case 'best_seller':
      delete newFilter.new;
      newFilter.best_seller = {
        eq: 1,
      };

      break;
    case 'new':
      delete newFilter.best_seller;
      newFilter.new = {
        eq: 1,
      };

      break;
    case 'sku':
      if (!isFirstFilter) {
        if (newFilter.hasOwnProperty('category_uid')) {
          delete newFilter.category_uid;
        }
        if (newFilter.hasOwnProperty('brand_name')) {
          newFilter.brand_name.in = [];
          newFilter.brand_name.in = filter;
        } else {
          newFilter.brand_name = {};
          newFilter.brand_name.in = filter;
        }
      } else {
        if (newFilter.hasOwnProperty('category_uid')) {
          delete newFilter.category_uid;
        }
        if (newFilter.hasOwnProperty('sku')) {
          delete newFilter.sku;
        }
        newFilter.sku = {
          in: filter,
        };
      }
      break;
    case 'remove_search':
      delete newFilter.sku;
      break;
    case 'remove':
      delete newFilter.best_seller;
      delete newFilter.new;
      break;
    case 'remove_price':
      delete newFilter.price;

      break;
    default:
      return;
  }
  state.filterJson = newFilter;
  state.firstFilters = isFirstFilter ? { ...newFilter } : { ...state.firstFilters };
};
export const addProduct = (state, { payload }) => {
  state.product = payload;
};
