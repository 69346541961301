/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-case-declarations */
/* eslint-disable no-redeclare */
/* eslint-disable no-var */
/* eslint-disable no-plusplus */
/* eslint-disable block-scoped-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import parse, { domToReact } from 'html-react-parser';
import { Col, Row } from 'react-bootstrap';

const SLIDER = '[data-content-type=slider]';
const ROW = 'row';
const PRODUCTS = '[data-content-type=products]';
const SKU = '[data-product-sku]';
const COLUMN = 'column';
const COLUMN_GROUP = 'column-group';
const COLUMN_LINE = 'column-line';

export const parseHtml = (html) => {
  const data = html;
  const htmlArray = [];
  data.replaceAll('"', '');
  let cleanHtml = new DOMParser().parseFromString(data, 'text/html');
  const styles = cleanHtml.querySelectorAll('style');
  // styles.forEach(style=>{
  //   const parent = style.parentNode;

  // })
  cleanHtml = cleanHtml.body.innerHTML;

  // cleanHtml.forEach(ele => {
  //   if (ele.getAttribute('data-content-type')) {
  //     switch (ele.getAttribute('data-content-type')) {
  //       case ROW :
  //         return (<Row {...ele.attribs} >{ele.children}</Row>)
  //       case COLUMN:
  //         return <Col {...ele.attribs} >{ele.children}</Col>
  //       case COLUMN_GROUP:
  //         const parent = ele;
  //         const grandParent = ele.parentNode;
  //         grandParent.replaceChild(parent.firstChild,parent)
  //       break
  //       default:
  //         return ele
  //     }
  //   }
  // })

  // console.log("SDasdsa" , cleanHtml)
  const options = {
    trim: true,
    replace: (domNode) => {
      if (domNode.attribs) {
        if (!domNode.attribs['data-content-type']) {
          return <>{domToReact(domNode.children, options)}</>;
        }
        switch (domNode.attribs['data-content-type']) {
          case ROW:
            return <Row {...domNode.attribs}>{domToReact(domNode.children, options)}</Row>;
          case COLUMN:
            return <Col {...domNode.attribs}>{domToReact(domNode.children, options)}</Col>;
          case COLUMN_LINE:
            return <>{domToReact(domNode.children, options)}</>;
          default:
            return domNode;
        }
      }
    },
  };
  return parse(cleanHtml, options);
};

export const parseSlider = (content) => {};
export const parseProduct = (content) => {
  const sku = [];
  content.forEach((pro) => {
    const skus = pro.element.querySelectorAll(SKU);
    skus.forEach((form) => {
      sku.push(form.getAttribute('data-product-sku'));
    });
  });
  return { sku };
};

export const IsToken = () => {
  const token = localStorage.getItem('token');

  if (token) {
    return true;
  }
  return false;
};
