import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import AppRouter from 'Routes';
import ErrorBoundary from 'Utils/ErrorBoundary';
import GraphqlProvider from 'Gql';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import GlobalLoading from 'Components/Global/GlobalLoading';
import { useDispatch } from 'react-redux';
import { changeTrigger } from 'Features/login';
import { IsB2BRoute } from 'B2B/Utils/IsB2B';
import { BrowserRouter } from 'react-router-dom';

/**
 *  @returns { ReactElement | any}  Main App File For This Project
 */
function App() {
  const dispatch = useDispatch();
  window.scrollTo({
    top: 0,
    left: 0,
  });

  useEffect(() => {
    const handleBackNavigation = () => {
      document.body.classList.remove('disable-scroll');
    };
    window.addEventListener('popstate', handleBackNavigation);
    return () => {
      window.removeEventListener('popstate', handleBackNavigation);
    };
  }, []);

  const isB2bRoute = IsB2BRoute();
  useEffect(() => {
    if (!isB2bRoute) {
      if (
        (!localStorage.getItem('firstTime') || localStorage.getItem('firstTime') === true) &&
        !localStorage.getItem('token')
      ) {
        dispatch(changeTrigger());
        localStorage.setItem('firstTime', false);
      }
    }
  }, [localStorage.getItem('firstTime')]);

  return (
    <>
      <GlobalLoading />
      <ErrorBoundary>
        <GraphqlProvider>
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </GraphqlProvider>
      </ErrorBoundary>
      <ToastContainer />
    </>
  );
}

export default App;
