function generatePassword() {
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const digitChars = '0123456789';
  const specialChars = '!@#$%^&*()_-+=?';

  const getRandomChar = (charset) => {
    const randomIndex = Math.floor(Math.random() * charset.length);
    return charset[randomIndex];
  };

  const getRandomValues = (charset, length) => {
    const buffer = new Array(length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      buffer[i] = getRandomChar(charset);
    }
    return buffer.join('');
  };

  const lowercase = getRandomChar(lowercaseChars);
  const uppercase = getRandomChar(uppercaseChars);
  const digit = getRandomChar(digitChars);
  const special = getRandomChar(specialChars);
  const remainingLength = 6;

  const randomChars = getRandomValues(
    lowercaseChars + uppercaseChars + digitChars + specialChars,
    remainingLength,
  );

  const password = lowercase + uppercase + digit + special + randomChars;

  return password;
}

export default generatePassword;
