import { useEffect, useRef, useState } from 'react';
import gstCertifcateImg from 'B2B/Assets/Images/gstCertificateImg.svg';
import fileIcon from 'B2B/Assets/Icons/fileIcon.svg';
import removeUploadedFile from 'B2B/Assets/Icons/removeUploadFile.svg';
import { useMutation } from '@apollo/client';
import { CREATE_COMPANY, UPDATE_GST_DETAILS } from 'B2B/Gql';
import { useSelector } from 'react-redux';
import { getBase64 } from 'B2B/Utils/GetBase64';
import { GENERATE_CUSTOMER_TOKEN_MOBILE } from 'Gql/query';
import './GSTCertificate.css';
import { notification } from 'Utils/Toast';

function GSTCertificate({ hasLoggedIn }) {
  const [createCompanyMutation] = useMutation(CREATE_COMPANY);
  const [updateGstDetails] = useMutation(UPDATE_GST_DETAILS);
  const [GenerateCustomerTokenMobile] = useMutation(GENERATE_CUSTOMER_TOKEN_MOBILE);

  const b2bSignInState = useSelector((state) => state.b2bSignIn);

  const [uploadedFile, setUploadedFile] = useState();
  const [uploadedFileError, setUploadedFileError] = useState(false);
  const fileInputRef = useRef();
  const uploadedFileSizeLimitInBytes = 1024 * 1024;
  const allowedFileTypes = ['image/png', 'application/pdf', 'image/jpeg', 'image/jpg'];

  const fileUploadingHandler = () => {
    fileInputRef.current.click();
  };

  const fileDragOverHandler = (event) => {
    event.preventDefault();
  };

  const fileOnDropHandler = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setUploadedFile(file);
  };

  const fileUploadedHandler = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const removeUploadedFileHandler = () => {
    setUploadedFile(null);
  };

  const openFileInNewTab = () => {
    if (uploadedFile) {
      const fileUrl = URL.createObjectURL(uploadedFile);
      window.open(fileUrl, '_blank');
    }
  };

  const onSubmitHandler = async () => {
    try {
      if (!hasLoggedIn) {
        const response = await createCompanyMutation({
          variables: {
            company_name: b2bSignInState?.entityName,
            firstname: b2bSignInState?.entityName?.split(' ')?.[0],
            lastname: b2bSignInState?.entityName?.split(' ')?.slice(1).join(' '),
            company_email: b2bSignInState?.email,
            mobilenumber: `91${b2bSignInState?.mobileNumber}`,
            gstin: b2bSignInState?.gstin,
            fileName: uploadedFile?.name,
            base64_encoded_file: await getBase64(uploadedFile),
            password: 'password',
            street: `${b2bSignInState?.address?.street1} ${b2bSignInState?.address?.street2}`,
            city: b2bSignInState?.address?.city,
            pincode: b2bSignInState?.address?.pincode,
            region_code: b2bSignInState?.address?.state_code,
            country_id: b2bSignInState?.address?.country,
          },
        });

        if (response?.data?.createCompany) {
          const response = await GenerateCustomerTokenMobile({
            variables: {
              mobile: `91${b2bSignInState?.mobileNumber}`,
              password: 'password',
            },
          });
          if (response?.data?.generateCustomerTokenMobile) {
            localStorage.setItem('token', response?.data?.generateCustomerTokenMobile?.token);
            localStorage.setItem('isB2B', true);
            notification.success('Yay! You have registered successfully');
            window.location.href = '/';
          } else {
            setUploadedFileError(response?.errors?.[0]?.message);
          }
        } else {
          setUploadedFileError(response?.errors?.[0]?.message);
        }
      } else {
        const response = await updateGstDetails({
          variables: {
            businessAddress: b2bSignInState?.principalAddress,
            customerGstin: b2bSignInState?.gstin,
            name: uploadedFile?.name,
            base64EncodedFile: await getBase64(uploadedFile),
            gstinEntityName: b2bSignInState?.entityName,
          },
        });

        if (response?.data?.UpdateGstDetails) {
          window.location.href = '/profile/GST-input-credit';
        }
      }
    } catch (err) {
      setUploadedFileError('An unexpected error occurred.');
    }
  };

  useEffect(() => {
    if (uploadedFile) {
      if (!allowedFileTypes.includes(uploadedFile.type)) {
        setUploadedFileError('Please upload a file in a supported format.');
      } else if (uploadedFileSizeLimitInBytes < uploadedFile?.size) {
        setUploadedFileError('File size exceeds limit');
      } else {
        setUploadedFileError();
      }
    } else {
      setUploadedFileError();
    }
  }, [uploadedFile]);

  return (
    <div className='gstCertificate'>
      {uploadedFile && (
        <div className='gstCertifcate-uploaded'>
          <img src={fileIcon} alt='fileIcon' />
          <div className='gstCertificate-fileInfo'>
            <div className='gstCertificate-fileDetails'>
              <div className='gstCertificate-fileName'>{uploadedFile?.name}</div>
              <div className='gstCertificate-fileOptions'>
                <div
                  className='gstCertificate-fileView'
                  onClick={() => openFileInNewTab()}
                  tabIndex={0}
                  role='button'
                >
                  view
                </div>
                <img
                  className='gstCertificate-fileVRemove'
                  src={removeUploadedFile}
                  onClick={() => {
                    removeUploadedFileHandler();
                  }}
                  tabIndex={0}
                  role='button'
                  alt='removeUploadedFile'
                />
              </div>
            </div>
            <div className={`gstCertificate-fileLoading ${uploadedFileError ? 'red' : 'green'}`} />
            {uploadedFileError && (
              <div className='gstCertificate-fileError'>{uploadedFileError}</div>
            )}
          </div>
        </div>
      )}
      <div className='gstCertificate-inputHeader'>GSTIN Certificate *</div>
      <div className='gstCertificate-input'>
        <input
          type='file'
          ref={fileInputRef}
          accept='.png, .pdf, .jpeg, .jpg'
          onChange={(event) => fileUploadedHandler(event)}
        />
        <img
          src={gstCertifcateImg}
          alt='gstCertificate'
          tabIndex={0}
          role='button'
          onClick={() => fileUploadingHandler()}
          onDragOver={(event) => fileDragOverHandler(event)}
          onDrop={(event) => fileOnDropHandler(event)}
        />
      </div>
      <div className='gstCertificate-btn'>
        <button
          className='gstCertificate-submit whiteCta'
          disabled={!uploadedFile || uploadedFileError}
          tabIndex={0}
          type='button'
          onClick={() => onSubmitHandler()}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
}

export default GSTCertificate;
