/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import somethingWentWrong from 'assets/images/something_went_wrong.png';
import 'Components/ErrorBoundary/style.css';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <div className='error-container text-center'>
            <img src={somethingWentWrong} className='img-fluid error_img' alt='' />
            <h2
              style={{ fontSize: '2vw', fontWeight: 'normal', fontFamily: 'var(--font-family)' }}
              className='text-center'
            >
              Something went wrong
            </h2>
            <div className='errorbutton d-flex'>
              <button type='button' className='trybutton' onClick={() => window.location.reload()}>
                TRY AGAIN
              </button>
              <button
                type='button'
                className='trybutton'
                onClick={() => {
                  window.location.href = '/';
                  window.location.reload();
                }}
              >
                RETURN TO HOME PAGE
              </button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
