import {
  addToCart,
  getCartDetails,
  getCustomerCartCount,
  removeFromCart,
  updateCartItem,
} from 'Features/cart/cartSlice';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const ADDED_TO_CART = 'ADDED_TO_CART';
export const REMOVED_FROM_CART = 'REMOVED_FROM_CART';
export const UPDATED_CART = 'UPDATED_CART';
export const QUICK_BUY_WITH_SKU = 'QUICK_BUY_WITH_SKU';

const useListenAllCartEvents = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addToCartEvent = useCallback(
    (event) => {
      const data = JSON.parse(event.detail || '{}');
      dispatch(
        addToCart({
          ...data,
        }),
      );
      dispatch(getCustomerCartCount());
      dispatch(getCartDetails());
    },
    [dispatch],
  );
  const removeFromCartEvent = useCallback(
    (event) => {
      const { detail } = event;
      dispatch(removeFromCart(detail));
      dispatch(getCustomerCartCount());
      dispatch(getCartDetails());
    },
    [dispatch],
  );
  const updateCartEvent = useCallback(
    (event) => {
      const data = JSON.parse(event.detail || '{}');
      dispatch(
        updateCartItem({
          ...data,
        }),
      );
      dispatch(getCustomerCartCount());
      dispatch(getCartDetails());
    },
    [dispatch],
  );
  const quickBuyEvent = useCallback(
    async (event) => {
      const data = JSON.parse(event.detail || '{}');
      try {
        dispatch(addToCart({ ...data }));
        navigate('/shopping-cart');

        dispatch(getCustomerCartCount());
        dispatch(getCartDetails());
      } catch (error) {
        console.error('Failed to complete quick buy event:', error);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const cart_add = window.addEventListener(ADDED_TO_CART, addToCartEvent);
    const cart_remove = window.addEventListener(REMOVED_FROM_CART, removeFromCartEvent);
    const update_cart = window.addEventListener(UPDATED_CART, updateCartEvent);
    const quick_buy = window.addEventListener(QUICK_BUY_WITH_SKU, quickBuyEvent);
    return () => {
      window.removeEventListener(ADDED_TO_CART, cart_add);
      window.removeEventListener(REMOVED_FROM_CART, cart_remove);
      window.removeEventListener(UPDATED_CART, update_cart);
      window.removeEventListener(QUICK_BUY_WITH_SKU, quick_buy);
    };
  }, [addToCartEvent, removeFromCartEvent, updateCartEvent, quickBuyEvent]);
};

export default useListenAllCartEvents;
