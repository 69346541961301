import JSONCrush from 'jsoncrush';

export const encodeData = (jsonData) =>
  JSONCrush.crush(encodeURIComponent(JSONCrush.crush(JSON.stringify(jsonData))));

export const decodedData = (encodedData) =>
  encodedData
    ? JSON.parse(JSONCrush.uncrush(decodeURIComponent(JSONCrush.uncrush(encodedData))))
    : JSON.parse(
        JSONCrush.uncrush(
          decodeURIComponent(
            JSONCrush.uncrush(
              JSONCrush.crush(encodeURIComponent(JSONCrush.crush(JSON.stringify({})))),
            ),
          ),
        ),
      );
