import { useEffect, useState } from 'react';
import gbLogo from 'B2B/Assets/Icons/G-logo.svg';
import close from 'B2B/Assets/Icons/close.svg';
import cartLeft from 'B2B/Assets/Icons/caretLeft.svg';
import SignIn from '../SignIn/SignIn';
import GST from '../GST/GST';
import GSTVerification from '../GSTVerification/GSTVerification';
import GSTCertificate from '../GSTCertificate/GSTCertificate';
import SignInOTPVerification from '../SignInOTPVerification/SignInOTPVerification';
import './Form.css';

const Form = ({ isLoginOpen, setIsLoginOpen, hasLoggedIn }) => {
  const formType = [SignIn, SignInOTPVerification, GST, GSTVerification, GSTCertificate];

  const [currentFormIndex, setCurrentFormIndex] = useState(() => (hasLoggedIn ? 2 : 0));

  useEffect(() => {
    if (isLoginOpen) {
      document.querySelector('html').classList.add('disable-scroll');
    } else {
      document.querySelector('html').classList.remove('disable-scroll');
    }

    setCurrentFormIndex(() => (hasLoggedIn ? 2 : 0));

    const elements = document.getElementsByClassName('form-icon');

    if (elements?.length > 0 && window.innerWidth < 991) {
      const element = elements[0];
      const react = element.getBoundingClientRect();
      const computedWidth = react.width;

      element.style.top = `-${computedWidth / 3}px`;
      element.style.left = `calc(50% - ${computedWidth / 3}px)`;
    }
  }, [isLoginOpen]);

  const prevForm = () => {
    setCurrentFormIndex((prevValue) => prevValue - 1);
  };

  const nextForm = () => {
    setCurrentFormIndex((prevValue) => prevValue + 1);
  };

  const closeButtonHandler = () => {
    setIsLoginOpen((prevValue) => !prevValue);
  };

  const renderCurrentForm = () => {
    const CurrentFormComponent = formType[currentFormIndex];
    return (
      <CurrentFormComponent
        nextForm={nextForm}
        prevForm={prevForm}
        closeButtonHandler={closeButtonHandler}
        hasLoggedIn={hasLoggedIn}
      />
    );
  };

  return (
    <div className={`form ${isLoginOpen && 'active'}`}>
      {isLoginOpen && (
        <>
          <div className='form-top'>
            {currentFormIndex !== 0 && (
              <img
                className='form-back'
                src={cartLeft}
                tabIndex={0}
                role='button'
                onClick={() => prevForm()}
                alt='backBtn'
              />
            )}
            <div className='form-img' />
            <img
              className='form-close'
              src={close}
              tabIndex={0}
              role='button'
              onClick={() => closeButtonHandler()}
              alt='closeIcon'
            />
            <img className='form-icon' src={gbLogo} alt='gbLogo' />
          </div>
          <div className='form-bottom'>
            <div className='form-heading'>
              {hasLoggedIn && currentFormIndex === 2
                ? 'Your GST is not registered with us. To Avail GST input credit please add your GSTIN Number'
                : 'Your one-stop destination for all things beauty and personal care'}
            </div>
            {renderCurrentForm()}
          </div>
        </>
      )}
    </div>
  );
};

export default Form;
