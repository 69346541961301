import * as Yup from 'yup';

export const SignInSchema = Yup.object({
  mobileNumber: Yup.string()
    .matches(/^[5-9]\d{9,}$/, 'Please enter a valid mobile number')
    .required('Please enter mobile number'),
  tnc: Yup.boolean().oneOf([true], 'Please check this'),
});

export const GSTSchema = Yup.object({
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Please enter a valid Email ID')
    .required('Please enter a Email ID'),
  gstin: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, 'Please enter a valid 15-digit GSTIN')
    .length(15, 'Please enter a valid 15-digit GSTIN')
    .required('Please enter a 15-digit GSTIN'),
});

export const GSTSchemaNoEmail = Yup.object({
  gstin: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, 'Please enter a valid 15-digit GSTIN')
    .length(15, 'Please enter a valid 15-digit GSTIN')
    .required('Please enter a 15-digit GSTIN'),
});
