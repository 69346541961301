/* eslint-disable no-param-reassign */
const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  isNewCompany: false,
  mobileNumber: '',
  email: '',
  gstin: '',
  entityName: '',
  principalAddress: '',
  address: {},
};

const signInSlice = createSlice({
  name: 'signInSignUp',
  initialState,
  reducers: {
    setIsNewCompany: (state, action) => {
      state.isNewCompany = action.payload;
    },
    setMobileNumber: (state, action) => {
      state.mobileNumber = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setGSTIn: (state, action) => {
      state.gstin = action.payload;
    },
    setEntityName: (state, action) => {
      state.entityName = action.payload;
    },
    setPrincipalAddress: (state, action) => {
      state.principalAddress = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
  },
});

export const {
  setIsNewCompany,
  setMobileNumber,
  setEmail,
  setGSTIn,
  setEntityName,
  setPrincipalAddress,
  setAddress,
} = signInSlice.actions;

export default signInSlice.reducer;
