/* eslint-disable no-use-before-define */
import { toast } from 'react-toastify';
import './toast.css';

const toastOptions = {
  position: toast.POSITION.BOTTOM_CENTER,
  hideProgressBar: true,
  theme: 'light',
  icon: false,
  closeButton: false,
  autoClose: 3000,
  progress: undefined,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

// default success
function success(message) {
  toast.success(message, {
    ...toastOptions,
  });
}

// default error
function error(message) {
  toast.error(message, {
    ...toastOptions,
  });
}

// default warn
function warn(message) {
  toast.warn(message, {
    ...toastOptions,
  });
}

// info warn
function info(message) {
  toast.info(message, {
    ...toastOptions,
  });
}

export const notification = {
  success,
  error,
  warn,
  info,
};
