import { client as postClient } from 'Routes/index';
import { client as getClient } from 'Gql';
import {
  WISHLIST_ID,
  WISHLIST,
  UPDATE_WISHLIST_VARIANTS,
  ADD_TO_WISHLIST,
  GET_ALL_WISHLIST,
} from 'Gql/Wishlist';
import { MOVE_WISHLIST_TO_CART, REMOVE_FROM_WISHLIST } from 'Gql/Global';
import { setAuth } from 'Utils/setAuth';

// Fetch the all address
const getWishlistID = async () => {
  const response = await getClient
    .query({
      query: WISHLIST_ID,
      fetchPolicy: 'no-cache',
      context: setAuth(),
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response.data.customer.wishlists[0];
};

// const getWishlistCount = async (state) => {
//   const response = await postClient
//     .query({
//       query: WISHLIST_COUNT,
//       fetchPolicy: 'no-cache',
//       context: setAuth(),
//       variables: {
//         id: state.wishlist.wishlistID,
//       },
//     })
//     .catch((err) => {
//       throw new Error(err);
//     });

//   return response.data.customer?.wishlist_v2.items_count;
// };

const getWishlist = async (arg) => {
  const { currentPage, pageSize } = arg;
  const response = await getClient
    .query({
      query: WISHLIST,
      fetchPolicy: 'no-cache',
      context: setAuth(),
      variables: {
        currentPage,
        pageSize,
      },
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response?.data?.customer.wishlists[0]?.items_v2?.items;
};

const getAllWishlistOnFirstLoad = async (arg) => {
  const { currentPage, pageSize } = arg;
  const response = await getClient
    .query({
      query: WISHLIST,
      fetchPolicy: 'no-cache',
      context: setAuth(),
      variables: {
        currentPage,
        pageSize,
      },
    })
    .catch((err) => {
      throw new Error(err);
    });
  return response?.data?.customer.wishlists[0];
};

const addToWishlist = async ({ sku, selectedOptions }, { wishlist }) => {
  const response = await postClient.mutate({
    mutation: ADD_TO_WISHLIST,
    context: setAuth(),
    variables: {
      wishlistID: wishlist.wishlistID,
      sku,
      selectedOptions,
    },
  });
  return { payload: response?.data?.addProductsToWishlist.wishlist };
};

const removeFromWishlist = async (id, state) => {
  const response = await postClient.mutate({
    mutation: REMOVE_FROM_WISHLIST,
    context: setAuth(),
    variables: {
      wishlistID: state.wishlist.wishlistID,
      id,
    },
  });
  return { payload: response.data.removeProductsFromWishlist.wishlist, id };
};
const moveWishlistToCart = async (id, state) => {
  const response = await postClient.mutate({
    mutation: MOVE_WISHLIST_TO_CART,
    context: setAuth(),
    variables: {
      items: [id],
      wishlistID: state.wishlist.wishlistID,
    },
  });
  return response.data;
};

const updateWishlistVariants = async (data, state) => {
  const response = await postClient.mutate({
    mutation: UPDATE_WISHLIST_VARIANTS,
    context: setAuth(),
    variables: {
      itemid: data.itemID,
      selectedOptions: data.selectedOptions,
      wishlistID: state.wishlist.wishlistID,
    },
  });

  return response?.data?.updateProductsInWishlist?.wishlist;
};

const getAllWishlist = async () => {
  const response = await getClient
    .query({
      query: GET_ALL_WISHLIST,
      context: setAuth(),
      fetchPolicy: 'no-cache',
      variables: {
        currentPage: 0,
        pageSize: 0,
      },
    })
    .catch((err) => {
      throw new Error(err);
    });

  return response?.data?.customer.wishlists[0]?.items_v2?.items;
};
const wishlistServices = {
  getWishlistID,
  // getWishlistCount,
  updateWishlistVariants,
  getWishlist,
  addToWishlist,
  removeFromWishlist,
  moveWishlistToCart,
  getAllWishlist,
  getAllWishlistOnFirstLoad,
};

export default wishlistServices;
