import { SEARCH_BRAND_LIST } from 'Gql/query';
import { useQuery } from '@apollo/client';

const useBrandsData = () => {
  const { data, loading, error } = useQuery(SEARCH_BRAND_LIST);
  if (!loading && !error) {
    return data;
  }
  return null;
};

export default useBrandsData;
