import React from 'react';
import PropTypes from 'prop-types';

function InnerHtml({ html, element, className, style, ...other }) {
  return React.createElement(`${element || 'div'}`, {
    className,
    style,
    ...other,
    dangerouslySetInnerHTML: { __html: html },
  });
}

InnerHtml.propTypes = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      html: PropTypes.element.isRequired,
      element: PropTypes.string,
      className: PropTypes.string,
      style: PropTypes.object,
    }),
  ).isRequired,
};

export default InnerHtml;
