import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import InnerHtml from 'Components/Global/InnerHtml';
import { LoyaltyPointSkeleton } from 'Components/Global/Skeleton';
import { CMS_CONTENT } from 'Gql/query';
import LoyaltyTnc from './LoyaltyTnc';

const RightSideContent = () => {
  const { pageID } = useParams();
  const { data, loading, error } = useQuery(CMS_CONTENT, {
    variables: {
      label: pageID,
    },
  });

  window.scrollTo({
    top: 0,
    left: 0,
  });

  useEffect(() => {
    if (error) window.location.href = '/';
  }, [loading]);

  return (
    <div className='col-md-8 col-lg-8 col-xl-9 px-0 px-md-2'>
      <div className='ordersProfileWrapper c-clr'>
        {loading ? (
          <LoyaltyPointSkeleton />
        ) : pageID !== 'loyalty-t-c' ? (
          <>
            <span className='side-back'>
              <h3 className='cancellation-return'>{data?.cmsPage?.content_heading}</h3>
            </span>
            <div className='show-underline' />
            <div className='acccor'>
              <InnerHtml element='div' html={data?.cmsPage?.content} />
            </div>
          </>
        ) : (
          <LoyaltyTnc />
        )}
      </div>
    </div>
  );
};

export default RightSideContent;
