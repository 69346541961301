import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Skeleton.css';

/**
 *
 * @param props
 */
function Skeleton(props) {
  return (
    <div {...props} className={`skeleton  position-relative ${props.className}`}>
      {props.children}
    </div>
  );
}

export default Skeleton;
/**
 *
 */
export function AccordionSkeleton() {
  const AccordianSkeletonObj = {
    classnameAttribute: ' skeleton round rounded-1',
    styleAttribute: {
      height: '10px',
      padding: '20px',
      margin: '20px auto',
      width: '95%',
    },
  };

  return (
    <div className=' h-auto  py-3 round rounded-1' data-testid='accordion-skeleton'>
      {[1, 2, 3, 4, 5].map((index) => (
        <div
          key={index}
          className={AccordianSkeletonObj.classnameAttribute}
          style={AccordianSkeletonObj.styleAttribute}
        />
      ))}
      <div className=' skeleton' style={AccordianSkeletonObj.styleAttribute} />
      {/* <div className="skeleton-title position-absolute w-75 mx-auto top-50 skeleton h-25"></div> */}
    </div>
  );
}

/**
 *
 * @param props
 */
export function CommunityBannerSkeleton(props) {
  return (
    <div
      {...props}
      className='  d-flex pb-4 round rounded-1 align-items-center justify-content-center'
      style={{ height: '85vh' }}
    >
      <div className='skeleton h-100 w-100 mobileHomeSkeleton d-flex align-items-center justify-content-between'>
        <div
          className='skeleton d-none mobileHomeSkeleton-arrow'
          style={{ height: '60px', width: '60px', marginLeft: '30px' }}
        />
        <div
          className='skeleton d-none mobileHomeSkeleton-arrow'
          style={{ height: '60px', width: '60px', marginRight: '30px' }}
        />
      </div>
    </div>
  );
}

export function FooterSkeleton() {
  return (
    <div
      className='container'
      style={{
        minHeight: '100vh',
      }}
    >
      <div className='row'>
        {[1, 2, 3].map((index) => (
          <div key={index} className='col-sm-12 col-md-2' style={{ height: '200px' }} />
        ))}
        <div
          className='col-sm-12 col-md-6 skeleton'
          style={{
            height: '200px',
          }}
        />
      </div>
    </div>
  );
}

// Address Skelton
export function SkeletonAddress() {
  return (
    <Skeleton
      style={{
        height: '200px',
        background: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        marginTop: '1rem',
      }}
    >
      <Skeleton
        style={{
          width: '40%',
          height: '30px',
          borderRadius: 0,
        }}
      />
      <Skeleton
        style={{
          width: '90%',
          height: '30px',
          borderRadius: 0,
        }}
      />
      <Skeleton
        style={{
          width: '90%',
          height: '30px',
          borderRadius: 0,
        }}
      />
    </Skeleton>
  );
}

export function SkeletonPayment() {
  return (
    <div
      className='container  p-3'
      style={{
        maxWidth: '850px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        className='row my-4 skeleton-deep  p-3 '
        style={{
          borderRadius: 0,
        }}
      >
        <div className='col-sm-12 col-md-4 skeleton-deep pt-2'>
          {[1, 3, 4].map((key) => (
            <div
              key={key}
              className='skeleton'
              style={{
                margin: '20px 0',
                height: '100px',
                width: '780px',
                borderRadius: 0,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export function SkeletonCart() {
  return (
    <div
      className='container  p-3'
      style={{
        maxWidth: '1440px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        className='skeleton my-2'
        style={{
          width: '100%',
          height: '80px',
          borderRadius: 0,
          // border: 'solid 1px #000',
        }}
      />
      <div
        className='row my-4 h-100 skeleton-deep  p-3'
        style={{
          borderRadius: 0,
          // border: 'solid 1px #000',
        }}
      >
        <Skeleton className='col-sm-12 skeleton-deep col-md-8 h-100'>
          <div
            className='skeleton'
            style={{
              height: '100px',
              margin: '20px 0',
            }}
          />
          <div
            className='skeleton'
            style={{
              height: '100px',
              margin: '20px 0',
            }}
          />
          {[1, 3, 4, 56].map((key) => (
            <div key={key} className='bg-white position-relative gap-4 my-2 p-3 row'>
              <div
                className='position-absolute rounded rounded-5'
                style={{
                  width: '30px',
                  height: '30px',
                  top: '10px',
                  right: '10px',
                }}
              />
              <div
                className='skeleton col-2 '
                style={{
                  height: '200px',
                  borderRadius: 0,
                }}
              />
              <div
                className='col-9'
                style={{
                  height: '200px',
                }}
              >
                {[1, 2, 3].map((index) => (
                  <div
                    key={index}
                    className='skeleton'
                    style={{ height: '20px', margin: '15px 0 ' }}
                  />
                ))}
              </div>
            </div>
          ))}
        </Skeleton>
        <div className='col-sm-12 col-md-4 skeleton-deep pt-2'>
          {[1, 3, 4, 56].map((key) => (
            <div
              key={key}
              className='skeleton'
              style={{
                margin: '20px 0',
                height: key === 56 ? '250px' : '100px',
                // border:'solid 1px #000',
                borderRadius: 0,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

// wishlist Skelton
export function SkeletonWishlist({ count }) {
  return (
    <Container>
      <Row>
        {[...Array(count)].map((_, index) => (
          <Col key={index} md={3}>
            <Skeleton
              className='skeleton-deep product-skeleton'
              style={{ background: 'transparent' }}
              key={index}
            >
              <Skeleton
                className='position-absolute product-skeleton-image sk-product'
                style={{
                  borderRadius: 3,
                  height: '21em',
                  width: '100%',
                  bottom: '30%',
                  left: '1%',
                }}
              />
              <Skeleton
                className='position-absolute product-skeleton-brand'
                style={{
                  height: '50px',
                  width: '100%',
                  bottom: '-3%',
                  left: '1%',
                }}
              />
              <Skeleton
                className='position-absolute product-skeleton-title'
                style={{
                  height: '20px',
                  width: '100%',
                  bottom: '10%',
                  left: '1%',
                }}
              />
              <Skeleton
                className='position-absolute product-skeleton'
                style={{
                  height: '20px',
                  width: '100%',
                  bottom: '15%',
                  left: '1%',
                }}
              />
              <Skeleton
                className='position-absolute'
                style={{
                  height: '20px',
                  width: '40%',
                  bottom: '20%',
                  left: '1%',
                }}
              />
            </Skeleton>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export function SkeletonJustINandBrandCard() {
  return (
    <Skeleton className='skeleton-deep  ' style={{ background: 'transparent', minHeight: '400px' }}>
      <Skeleton
        className='skeleton'
        style={{
          borderRadius: 3,
          height: '320px',
          width: '100%',
          marginBottom: '10px',
        }}
      />
      <Skeleton
        className='skeleton'
        style={{
          height: '26px',
          width: '50%',
          marginBottom: '6px',
        }}
      />
      <Skeleton
        className='skeleton'
        style={{
          height: '22px',
          width: '100%',
          marginBottom: '6px',
        }}
      />
      <Skeleton
        className='skeleton'
        style={{
          height: '22px',
          width: '100%',
          marginBottom: '6px',
        }}
      />
      <Skeleton
        className='skeleton '
        style={{
          height: '50px',
          width: '100%',
          margin: '10px !imortant',
          padding: '10px',
        }}
      />
    </Skeleton>
  );
}

export const MyOrderSkeleton = () => (
  <Skeleton
    className='skeleton-deep p-3 mx-1 mt-5  flex-column d-flex h-100 myorder-skeleton'
    data-testid='my-order-skeleton'
  >
    <div className='d-flex justify-content-between'>
      <Skeleton className='my-2' style={{ width: '35%', height: '45px' }} />
      <Skeleton className='my-2 ' style={{ width: '35%', height: '45px' }} />
    </div>
    <Skeleton className='my-2' style={{ height: '5px' }} />

    {[1, 2, 3, 4].map((_, index) => (
      <>
        <Skeleton className='my-10 ' style={{ height: '30px', width: '30%' }} />
        <Skeleton key={index + 1} className='h-25 w-100 my-2 ' />
      </>
    ))}
  </Skeleton>
);

export const OrderDetailsSkeleton = () => (
  <Skeleton className='skeleton-deep p-3 mx-1 mt-5 flex-column d-flex h-100'>
    <div className='d-flex justify-content-between'>
      <Skeleton className='my-2' style={{ width: '50%', height: '45px' }} />
      <Skeleton className='my-2 ' style={{ width: '30%', height: '45px' }} />
    </div>
    <Skeleton className='my-2 ' style={{ height: '5px' }} />
    {[1, 2, 3].map((index) => (
      <Skeleton
        key={index}
        className='skeleton-deep p-3 mx-1 mt-3 flex-column d-flex mb-3'
        style={{ height: '120px', marginTop: '0px' }}
      >
        <Skeleton className='my-2 ' style={{ height: '30px' }} />
        <Skeleton style={{ height: '30px', paddingTop: '10px' }} />
      </Skeleton>
    ))}
  </Skeleton>
);

export const OrderDetailsProduct = () => (
  <Skeleton className='skeleton-deep p-3 mx-1 mt-5 flex-column d-flex h-100'>
    <Skeleton className='my-2 ' style={{ height: '5px' }} />
    {[1, 2, 3].map((index) => (
      <Skeleton
        key={index}
        className='skeleton-deep p-3 mx-1 mt-3 flex-column d-flex'
        style={{ height: '120px', marginTop: '0px' }}
      >
        <Skeleton className='my-2 ' style={{ height: '30px' }} />
        <Skeleton style={{ height: '30px', paddingTop: '10px' }} />
      </Skeleton>
    ))}
  </Skeleton>
);

export const CouponCardSkeleton = () => (
  <div data-testid='CouponCardSkeleton'>
    {[1, 2, 3, 4].map((index) => (
      <Skeleton
        key={index}
        className='skeleton-deep p-3 mx-1 mt-3 flex-column d-flex'
        style={{ height: '120px', marginTop: '0px' }}
      >
        <Skeleton className='my-2 ' style={{ height: '30px' }} />
        <Skeleton style={{ height: '30px', paddingTop: '10px' }} />
      </Skeleton>
    ))}
  </div>
);

export const LoyaltyPointSkeleton = () => (
  <Skeleton
    className='skeleton-deep p-3 mx-1 mt-5 flex-column d-flex h-100'
    data-testid='loyalty-point-skeleton'
  >
    <div className='d-flex justify-content-between'>
      <Skeleton className='my-2' style={{ width: '30%', height: '60px' }} />
    </div>
    <div>
      <Skeleton
        className='p-2 mx-1 mt-3 flex-column d-flex'
        style={{ height: '100px', background: '#efefef' }}
      >
        <Skeleton className='my-2 ' style={{ height: '20px' }} />
        <Skeleton style={{ height: '20px', paddingTop: '10px' }} />
      </Skeleton>
    </div>
    <div className='d-flex justify-content-between mt-3'>
      <Skeleton className='my-2' style={{ width: '30%', height: '40px' }} />
    </div>
    <div>
      {[1, 2, 3, 4].map((ele) => (
        <Skeleton
          key={ele}
          className='col-12 mt-4 mx-auto flex-column d-flex'
          style={{ height: '70px' }}
        />
      ))}
    </div>
  </Skeleton>
);

export const BrandSerchResultsSkeleton = () => (
  <Skeleton className='skeleton-deep p-3 mx-1 mt-5 flex-column d-flex h-100'>
    {[1, 2, 3, 4].map((ele) => (
      <div className='d-flex justify-content-between'>
        <Skeleton key={ele} className='my-2' style={{ width: '10%', height: '120px' }} />
        <Skeleton style={{ marginRight: '993px', width: '10%', height: '15px' }} />
      </div>
    ))}
  </Skeleton>
);

export const ProductDetailsDescriptionSkeleton = () => (
  <>
    {/* <div className='breadcrumb_Wrapper skeleton'/> */}
    <div className='container-fluid'>
      <Skeleton className=' my-3 ' style={{ height: '30px', width: '50%' }} />
      <div className='row'>
        <div className='col-lg-6'>
          <Skeleton
            className='d-flex'
            style={{
              background: 'transparent',
            }}
          >
            <Skeleton
              className='d-flex flex-column   justify-content-evenly w-25 mx-2 pdpThump1'
              style={{
                background: 'transparent',
              }}
            >
              {[1, 2, 3, 4].map((index) => (
                <Skeleton key={index} className=' my-3 ' style={{ height: '70px', width: '50%' }} />
              ))}
            </Skeleton>

            <Skeleton
              className='d-flex w-100 minHightPDPMob'
              style={{ minHeight: '500px', marginBottom: '20px' }}
            />
          </Skeleton>
          <Skeleton
            className='d-flex flex-row justify-content-evenly w-100  pdpThump2'
            style={{
              background: 'transparent',
            }}
          >
            {[1, 2, 3, 4].map((index) => (
              <Skeleton
                key={index}
                className='mx-1 mobPdpThumb'
                style={{ height: '17vw', width: '30%' }}
              />
            ))}
          </Skeleton>
        </div>

        <div className='col-lg-6'>
          <Skeleton
            className='d-flex flex-column'
            style={{
              background: 'transparent',
            }}
          >
            <Skeleton
              className='w-100 my-1 skeleton-deep p-3'
              style={{
                height: '185px',
                borderRadius: 0,
              }}
            >
              <div className='d-flex justify-content-between'>
                <Skeleton
                  className='w-50   skeleton'
                  style={{
                    height: '30px',
                  }}
                />
                <div className='d-flex justify-content-between'>
                  <Skeleton
                    className='skeleton'
                    style={{
                      height: '30px',
                      width: '30px',
                      marginRight: '10px',
                    }}
                  />
                  <Skeleton
                    className='skeleton'
                    style={{
                      height: '30px',
                      width: '30px',
                    }}
                  />
                </div>
              </div>
              <Skeleton
                className='w-75   skeleton'
                style={{
                  height: '20px',
                  marginTop: '6px',
                }}
              />
              <Skeleton
                className='w-25   skeleton'
                style={{
                  height: '20px',
                }}
              />
              <Skeleton
                className='   skeleton'
                style={{
                  height: '20px',
                  marginTop: '30px',
                  width: '100px',
                }}
              />
              <Skeleton
                className='  skeleton'
                style={{
                  height: '20px',
                  width: '150px',
                  marginTop: '6px',
                }}
              />
            </Skeleton>

            <Skeleton
              style={{
                height: '220px',
              }}
              className='w-100  my-1 skeleton-deep pt-3 px-3'
            >
              <Skeleton
                className='w-100   skeleton'
                style={{
                  height: '100px',
                }}
              />
              <Skeleton
                className='w-100   skeleton'
                style={{
                  height: '60px',
                  marginTop: '30px',
                }}
              />
            </Skeleton>
          </Skeleton>
        </div>
      </div>
    </div>
  </>
);

export const ProductsSkeleton = () =>
  [1, 2, 3, 4, 5, 6, 7, 8, 99, 67, 575, 32].map((key) => (
    <Skeleton
      className='skeleton-deep position-relative'
      style={{ background: 'transparent' }}
      key={key}
    >
      <div className='product-image-holder'>
        <Skeleton className='product-image' />
      </div>
      <div
        className='product-info-holde'
        style={{
          gap: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Skeleton
          className='product-brand'
          style={{
            height: '1.1em',
          }}
        />
        <Skeleton
          className='product-title'
          style={{
            height: '1.1em',
          }}
        />
        <Skeleton
          className='price'
          style={{
            height: '1.3em',
          }}
        />
        <Skeleton
          className='viewDetailsButton'
          style={{
            margin: '5px 0',
            padding: '10px',
            border: 0,
          }}
        />
      </div>
    </Skeleton>
  ));

export const ProfileWishListSkeleton = () =>
  [1].map((key) => (
    <Skeleton
      className='skeleton-deep position-relative'
      style={{ background: 'transparent' }}
      key={key}
    >
      <div className='product-image-holder'>
        <Skeleton className='product-image' />
      </div>
      <div
        className='product-info-holde'
        style={{
          gap: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Skeleton
          className='product-brand'
          style={{
            height: '1.1em',
          }}
        />
        <Skeleton
          className='product-title'
          style={{
            height: '1.1em',
          }}
        />
        <Skeleton
          className='price'
          style={{
            height: '1.3em',
          }}
        />
        <Skeleton
          className='viewDetailsButton'
          style={{
            margin: '5px 0',
            padding: '10px',
            border: 0,
          }}
        />
      </div>
    </Skeleton>
  ));
