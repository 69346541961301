import React, { useState } from 'react';
import gstIcon from 'B2B/Assets/Icons/gstIcon.svg';
import highVolumeIcon from 'B2B/Assets/Icons/highVolumeIcon.svg';
import specialAccessIcon from 'B2B/Assets/Icons/specialAccessIcon.svg';
import deliveryIcon from 'B2B/Assets/Icons/deliveryIcon.svg';
import salonIcon from 'B2B/Assets/Icons/salonIcon.svg';
import Form from 'B2B/Components/SignInSignUp/Form/Form';
import './LandingPage.css';

function LandingPage() {
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const LoginRegistrationHandler = () => {
    setIsLoginOpen((prevValue) => !prevValue);
  };

  return (
    <>
      <div className={`lp ${isLoginOpen && 'disable'}`} />
      <div className='landing-page'>
        <div className='LP-content'>
          <h1 className='LP-main-heading'>
            Buy <span className='LP-main-heading-smarter'>smarter</span>, Style{' '}
            <span className='LP-main-heading-brighter'>brighter</span>
          </h1>
          <p className='LP-sub-heading'>Reshape your salon's shopping</p>
          <div className='LP-buttons'>
            <button type='button' onClick={() => LoginRegistrationHandler()}>
              Login / Register
            </button>
          </div>
        </div>
      </div>

      <div className='LP-sections-container'>
        <div className='LP-section'>
          <img src={gstIcon} alt='gst-icon' className='LP-logo' />
          <h2 className='LP-heading'>GST Invoice</h2>
          <p className='LP-details'>
            Double your savings! Get GST credit & bulk purchase discounts.
          </p>
        </div>

        <div className='LP-section'>
          <img src={highVolumeIcon} alt='high-volume-icon' className='LP-logo' />
          <h2 className='LP-heading'>High-volume discount</h2>
          <p className='LP-details'>Unlock exclusive discounts with high-volume purchases.</p>
        </div>

        <div className='LP-section'>
          <img src={specialAccessIcon} alt='special-access-icon' className='LP-logo' />
          <h2 className='LP-heading'>special access</h2>
          <p className='LP-details'>
            Elevate Your Work: Shop exclusive offers on professional-only products.
          </p>
        </div>

        <div className='LP-section'>
          <img src={deliveryIcon} alt='delivery-icon' className='LP-logo' />
          <h2 className='LP-heading'>to-your-door-delivery</h2>
          <p className='LP-details'>Pay with peace of mind, get it delivered with ease.</p>
        </div>

        <div className='LP-section'>
          <img src={salonIcon} alt='salon-icon' className='LP-logo' />
          <h2 className='LP-heading'>Streamline Salon Procurement</h2>
          <p className='LP-details'>Say goodbye to manual tasks and wasted resources.</p>
        </div>
      </div>
      <Form isLoginOpen={isLoginOpen} setIsLoginOpen={setIsLoginOpen} />
    </>
  );
}

export default LandingPage;
