/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  trigger: false,
  formType: 'login',
  username: {
    type: 'email',
    field: '',
  },
  codes: null,
};
const LoginSlice = createSlice({
  name: 'name',
  initialState,
  reducers: {
    changeFormType: (state, { payload }) => {
      state.formType = payload;
    },
    updateusername: (state, { payload }) => {
      state.username = {
        field: payload.field,
        type: payload.type,
      };
    },
    addCodes: (state, { payload }) => {
      state.codes = payload;
    },
    changeTrigger: (state, { payload }) => {
      state.trigger = payload ? false : !state.trigger;
    },
    openLoginModal: (state, { payload }) => {
      state.trigger = payload;
    },
  },
});

export const { changeFormType, addCodes, updateusername, changeTrigger, openLoginModal } =
  LoginSlice.actions;
export default LoginSlice.reducer;
