/* eslint-disable arrow-body-style */
import React from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  concat,
} from '@apollo/client';
import propTypes from 'prop-types';
import compressUrl from 'Utils/compressUrl';
import { IsB2BLogged } from 'B2B/Utils/IsB2B';

const URI = process.env.REACT_APP_API;

export { URI as URL };

const customFetch = (uri, options) => {
  return fetch(compressUrl(uri), options);
};

const requestMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext((request) => {
    return {
      headers: {
        ...request.headers,
        'Content-Transfer-Encoding': 'base64',
        'Sec-Fetch-Mode': 'no-cors',
        Authorization: `bearer ${localStorage.getItem('token')}`,
      },
    };
  });

  return forward(operation);
});

const link = new HttpLink({
  uri: URI,
  fetch: customFetch,
  ...(!IsB2BLogged() && { useGETForQueries: true }),
  preserveHeaderCase: '',
});

export const client = new ApolloClient({
  link: concat(requestMiddleware, link),
  cache: new InMemoryCache({
    typePolicies: {
      products: {
        keyFields: ['uid', 'sku'],
      },
    },
  }),
  connectToDevTools: true,
});

function GraphqlProvider({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
GraphqlProvider.propTypes = {
  children: propTypes.element.isRequired,
};
export default GraphqlProvider;
