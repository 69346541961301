import { getAnalytics, logEvent } from 'firebase/analytics';
import React, { useEffect } from 'react';

function NotFound() {
  const analytics = getAnalytics();
  useEffect(() => {
    logEvent(analytics, 'Page Not Found');
  });
  return (
    <div className='notfound'>
      <div className='container r-p'>
        <img src='https://i.stack.imgur.com/6M513.png' className='nf-img' alt='...' />
      </div>
    </div>
  );
}

export default NotFound;
