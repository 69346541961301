export const setAuth = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  };
};

export const useToken = () => () => ({
  headers: {
    authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
  },
});
