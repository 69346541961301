import { createSlice } from '@reduxjs/toolkit';
import { reducerAddFilterJson, addProduct } from './productActions';

const initialState = {
  filters: {},
  filterJson: {},
  firstFilters: {},
  product: {},
};
const ProductsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    addFilterJson: reducerAddFilterJson,
    addProducts: addProduct,
  },
});
export const { addFilterJson, addProducts } = ProductsSlice.actions;
export default ProductsSlice.reducer;
