import React from 'react';
import { useSelector } from 'react-redux';
import { getGlobalLoading } from 'Features/GlobalLoading/LoadingSlice';
import '../../Styles/globalLoading.css';

const GlobalLoading = () => {
  const isLoading = useSelector((state) => getGlobalLoading(state));

  return (
    <div>
      {isLoading && (
        <div className='loadingBackdrop'>
          <div className='bouncing-loader'>
            <div />
            <div />
            <div />
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobalLoading;
