/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DesktopHeader from '../Global/DesktopHeader';
import MobileHeader from '../Global/MobileHeader';

/**
 *
 * @param props
 */
function Header() {
  const [mobile, setMobile] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  const hasCheckoutAddress = pathname.includes('/checkout/address');

  let valuesAfterCheckoutAddress = null;
  if (hasCheckoutAddress) {
    const addressSplit = pathname.split('/checkout/address/');
    if (addressSplit.length === 2 && addressSplit[1].length > 0) {
      valuesAfterCheckoutAddress = addressSplit[1];
    }
  }

  if (!valuesAfterCheckoutAddress) {
    localStorage.removeItem('virtualParticipants');
  }

  useEffect(() => {
    if (window.innerWidth < 991) setMobile(true);
    const resizeListener = () => (window.innerWidth < 991 ? setMobile(true) : setMobile(false));
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  return (
    <>
      <DesktopHeader data-testid='desktop-header' />
      {mobile && <MobileHeader data-testid='mobile-header' />}
    </>
  );
}
export default Header;
