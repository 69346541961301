import { useEffect } from 'react';
import { GSTSchema, GSTSchemaNoEmail } from 'B2B/Utils/ValidationSchema';
import { useFormik } from 'formik';
import { IS_EMAIL_AVAILABLE, VALIDATE_GST } from 'B2B/Gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAddress,
  setEmail,
  setEntityName,
  setGSTIn,
  setPrincipalAddress,
} from 'B2B/Redux/SignInSignUp/SignIn';
import './GST.css';

function GST({ nextForm, hasLoggedIn }) {
  const dispatch = useDispatch();

  const emailState = useSelector((state) => state.b2bSignIn.email);
  const gstinState = useSelector((state) => state.b2bSignIn.gstin);

  const [validateGSTQuery] = useLazyQuery(VALIDATE_GST, {
    fetchPolicy: 'no-cache',
  });

  const [isEmailAvailableMutation] = useMutation(IS_EMAIL_AVAILABLE, {
    fetchPolicy: 'no-cache',
  });

  const formikProps = {
    initialValues: {
      email: emailState,
      gstin: gstinState,
    },
    validationSchema: hasLoggedIn ? GSTSchemaNoEmail : GSTSchema,
    validationOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { setFieldError }) => {
      try {
        let isEmailAvailableQueryPromise = null;
        if (!hasLoggedIn) {
          isEmailAvailableQueryPromise = new Promise((resolve, reject) => {
            isEmailAvailableMutation({
              variables: {
                email: values.email,
              },
            })
              .then((res) => {
                if (res?.data?.isEmailAvailable?.is_email_available) {
                  resolve();
                } else {
                  setFieldError(
                    'email',
                    'This Email ID is already used on glamourbook.com, kindly try with new one.',
                  );
                  reject();
                }
              })
              .catch(() => {
                setFieldError('email', 'An unexpected error occurred.');
                reject();
              });
          });
        }
        const validateGSTQueryPromise = new Promise((resolve, reject) => {
          validateGSTQuery({
            variables: {
              GST: values.gstin,
            },
          })
            .then((res) => {
              if (res?.data?.validateGST) {
                dispatch(setGSTIn(values.gstin));
                dispatch(setEntityName(res?.data?.validateGST?.name));
                dispatch(setPrincipalAddress(res?.data?.validateGST?.principal_address));
                dispatch(setAddress(res?.data?.validateGST?.address));
                resolve();
              } else {
                setFieldError('gstin', res?.errors?.[0]?.message);
                reject();
              }
            })
            .catch(() => {
              setFieldError('gstin', 'An unexpected error occurred.');
              reject();
            });
        });

        const promises = [validateGSTQueryPromise];

        if (isEmailAvailableQueryPromise) {
          promises.push(isEmailAvailableQueryPromise);
        }

        Promise.all(promises)
          .then(() => nextForm())
          .catch((err) => console.log(err));
      } catch (err) {
        console.log(err);
      }
    },
  };

  const { values, handleChange, errors, touched, handleBlur, handleSubmit } =
    useFormik(formikProps);

  useEffect(() => {
    dispatch(setEmail(values.email));
    dispatch(setGSTIn(values.gstin));
  }, [dispatch, values.email, values.gstin]);

  return (
    <div className='gst'>
      <form onSubmit={handleSubmit}>
        <div className='gst-inputHeader' style={hasLoggedIn && { display: 'none' }}>
          <div className='gst-inputText'>Email ID *</div>
          <input
            placeholder='Enter Email ID'
            name='email'
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email && touched.email && <div className='validationError'>{errors.email}</div>}
        </div>
        <div className='gst-inputHeader'>
          <div className='gst-inputText'>GSTIN *</div>
          <input
            placeholder='Enter GSTIN'
            name='gstin'
            maxLength={15}
            value={values.gstin}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.gstin && touched.gstin && <div className='validationError'>{errors.gstin}</div>}
        </div>
        <div className='gstin-btn'>
          <button className='gstin-submit whiteCta' tabIndex={0} type='submit'>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default GST;
