import axios from 'axios';
import { IsB2BLogged } from 'B2B/Utils/IsB2B';
import { useSelector } from 'react-redux';

const useSearchResultData = () => {
  const magentoCustomerGroupState = useSelector(
    (state) => state.storeConfig.magento_customer_group,
  );

  const getSearchResultData = async (value) => {
    const res = await axios({
      url: 'https://catalog-service-sandbox.adobe.io/graphql',
      method: 'post',
      headers: {
        'Magento-Environment-Id': process.env.REACT_APP_ENV_ID,
        'Magento-Website-Code': 'base',
        'Magento-Store-Code': 'main_website_store',
        'Magento-Store-View-Code': 'default',
        'X-Api-Key': '5275b72afb7b460fa5972f7e456c9916',
        ...(IsB2BLogged() && { 'Magento-Customer-Group': magentoCustomerGroupState }),
      },
      data: {
        query: `
                      {
                        productSearch(
                            phrase:"${decodeURIComponent(value)}"
                            page_size: 5 
                            )
                            {
                                total_count
                                items {
                                  product {
                                    name
                                    sku
                                    __typename
                                  }
                                }
                                suggestions
                            }
                      }`,
      },
    });
    return res;
  };

  return getSearchResultData;
};

export default useSearchResultData;
