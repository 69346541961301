import axios from 'axios';
import React, { useState } from 'react';

import OtpInput from 'react-otp-input';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { URL } from 'Gql';
import { addCodes, changeFormType, changeTrigger } from 'Features/login';
import styles from './loginpopup.module.css';

function ForgotPassword({ username }) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [err, seterr] = useState({});
  const [otp, setOtp] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(() => true);
    try {
      const forgotPasswordVerify = await axios({
        url: URL,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          query: `{
            forgotPasswordEmailOTPVerify(email: "${username.field}", otp: "${otp}", websiteId: 1) {
            message
            status 
          }
        }`,
        },
      });
      const { forgotPasswordEmailOTPVerify } = forgotPasswordVerify.data.data;
      if (forgotPasswordEmailOTPVerify.status) {
        setLoading(() => false);
        dispatch(addCodes(otp));
        dispatch(changeTrigger());
        dispatch(changeFormType('login'));
        navigate('/change-password');
      } else {
        setLoading(() => false);
        seterr({ otp: forgotPasswordEmailOTPVerify.message });
        setMessage(() => forgotPasswordEmailOTPVerify.message);
      }
    } catch (error) {
      setLoading(() => false);
      seterr(() => ({
        otp: error.message,
      }));
    }
  };

  // validators
  const validateOTP = (otp) => {
    if (otp) {
      if (/^\d+$/.test(otp)) {
        seterr('');
        setIsSubmit(() => true);
      } else {
        setIsSubmit(() => false);
        seterr({ otp: 'Enter numbers only' });
      }
    } else {
      setIsSubmit(() => false);

      seterr({ otp: 'Please enter 6 digit OTP' });
    }
  };
  // String Replace
  const replaceAt = function (string, start, replacement) {
    let end = string.search('@');
    if (end === -1) end = string.length - 3;
    const subString = string.substring(start, end);
    return string.replace(subString, replacement);
  };

  return (
    <div className='login rounded rounded-3' data-testid='forgotPassword'>
      <div className='login__top_image_holder'>
        {/* <img className='login_img' src={forgot_bg} alt='' /> */}
      </div>
      <div className='login__form  d-flex flex-column '>
        <h6 data-testid='masked-email'>
          Please enter the 6 digit code <br /> sent to {replaceAt(username.field, 3, '**********')}
        </h6>
        {message && (
          <div className='alert alert-success ' role='alert'>
            {message}
          </div>
        )}
        <OtpInput
          containerStyle={styles.input__holder}
          inputStyle={styles.otp_input}
          onChange={(otp) => {
            setOtp(() => otp);
            validateOTP(otp);
          }}
          value={otp}
          errorStyle={styles.otpinputStyle}
          hasErrored={err.otp}
          numInputs={6}
          data-testid='otp-input'
        />
        <p className='text-validation text-center' data-testid='otp_err'>
          {err.otp}
        </p>
        <input
          type='submit'
          disabled={!isSubmit}
          className='continue-button'
          id='submit'
          value={loading ? 'Loading..' : 'Submit'}
          data-testid='submit-button'
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  username: state.login.username,
});

export default connect(mapStateToProps)(ForgotPassword);
