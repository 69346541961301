import React from 'react';
import '../../Styles/globalLoading.css';

const ThreeDotsLoading = (props) => {
  const loading = props?.loading || true;
  const dotStyle = {
    width: `${props?.width}px` || '16px',
    height: `${props?.height}px` || '16px',
    margin: `${props.margin}` || '3px 2px',
  };
  return (
    <div>
      {loading && (
        <div className='bouncing-loader'>
          <div style={dotStyle} />
          <div style={dotStyle} />
          <div style={dotStyle} />
        </div>
      )}
    </div>
  );
};

export default ThreeDotsLoading;
