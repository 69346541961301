export const IsB2BLogged = () => {
  const isB2BLogged = localStorage.getItem('isB2B');
  return isB2BLogged;
};

export const IsB2BRoute = () => {
  const { pathname } = window.location;
  const isB2BRoute = pathname.includes('business');
  return isB2BRoute;
};

export const IsB2BEnable = () => {
  const isB2BEnable = process.env.REACT_APP_IS_B2B_ENABLE;
  return !!isB2BEnable;
};
