import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const CanonicalTag = () => {
  const { pathname } = useLocation();
  const baseUrl = window.location.origin;
  const canonicalUrl = baseUrl + pathname;

  return (
    <Helmet>
      <link rel='canonical' href={canonicalUrl} />
    </Helmet>
  );
};

export default CanonicalTag;
