import { gql } from '@apollo/client';

export const CREATE_CUSTOMER_ADDRESS = gql`
  mutation CreateCustomerAddress($input: CustomerAddressInput!) {
    createCustomerAddress(input: $input) {
      id
      region {
        region
        region_code
        region_id
      }
      firstname
      lastname
      country_code
      street
      telephone
      postcode
      city
      default_shipping
      default_billing
    }
  }
`;

export const GET_COUNTRIES = gql`
  query GetCountry($id: String!) {
    country(id: $id) {
      id
      two_letter_abbreviation
      three_letter_abbreviation
      full_name_locale
      full_name_english
      available_regions {
        id
        code
        name
      }
    }
  }
`;

export const ZIPCODE_QUERY = gql`
  query ZipcodeQuery($code: String!) {
    zipcode(code: $code) {
      status
      country
      city
      state
      country_id
      state_id
    }
  }
`;

export const CUSTOMER_ADDRESSES_QUERY = gql`
  query {
    customer {
      addresses {
        id
        firstname
        lastname
        street
        city
        region {
          region_code
          region
          region_id
        }
        postcode
        default_shipping
        country_code
        telephone
      }
    }
  }
`;

export const DELETE_CUSTOMER_ADDRESS = gql`
  mutation deleteCustomerAddress($id: Int!) {
    deleteCustomerAddress(id: $id)
  }
`;

export const UPDATE_CUSTOMER_ADDRESS = gql`
  mutation updateCustomerAddress($id: Int!, $input: CustomerAddressInput) {
    updateCustomerAddress(id: $id, input: $input) {
      id
      firstname
      lastname
      region {
        region
        region_code
        region_id
      }
      country_code
      street
      telephone
      postcode
      city
      default_shipping
      default_billing
    }
  }
`;
