import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import addressReducer from 'Features/address/addressSlice';
import CartReducers from 'Features/cart/cartSlice';
import WishlistReducers from 'Features/wishlist/wishlistSlice';
import ProductReducers from 'Features/products';
import LoginReducers from 'Features/login';
import GlobalReducers from 'Features/global';
import loadingReducer from 'Features/GlobalLoading/LoadingSlice';
import { dispatchActionsOnFirstLoad } from 'Features/global/globalAction';
import StoreConfigSlice from 'Features/storeConfig';
import SignInReducers from 'B2B/Redux/SignInSignUp/SignIn';

const store = configureStore({
  reducer: {
    addresses: addressReducer,
    wishlist: WishlistReducers,
    cart: CartReducers,
    products: ProductReducers,
    login: LoginReducers,
    global: GlobalReducers,
    loading: loadingReducer,
    storeConfig: StoreConfigSlice,
    b2bSignIn: SignInReducers,
  },
  // devTools: false
});

// store.dispatch(getCacheStore());
store.dispatch(dispatchActionsOnFirstLoad());
function GbStore({ children }) {
  return <Provider store={store}>{children}</Provider>;
}

export const rootReducer = combineReducers({
  addresses: addressReducer,
  wishlist: WishlistReducers,
  cart: CartReducers,
  products: ProductReducers,
  login: LoginReducers,
  global: GlobalReducers,
  loading: loadingReducer,
  storeConfig: StoreConfigSlice,
});

export default GbStore;
